import { startCall, httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType, NucleusSignaling, SignalingId } from '@nucleus-care/nucleuscare-connect-signaling';
import { UIModal, UINucleusContainer, useModal } from 'components/UI';
import { UINucleusContentContainer } from 'components/UI/NucleusContainer/Content';
import { UINucleusPageTitle } from 'components/UI/NucleusPageTitle/Default';
import { CallingMethods } from 'components/WebRTCCallView';
import { useNucleusProviders } from 'context-api/nucleusProvidersContext/NucleusProvidersContext';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthStore from 'stores/AuthStore';
import { CommunicationEvent } from 'stores/CareCommunicationStore';
import careCommunicationStore from 'stores/CareCommunicationStore';
import CareQueueStore from 'stores/CareQueueStore';
import styled from 'styled-components';
import MarkCallAsHandled from '../components/MarkCallAsHandled';
import PostCallDataModal from '../components/PostCallDataModal';
import { getProviderStrings } from '../utils/providerApi';
import { ProviderQueueRow } from './ProviderQueueRow';
import { ProviderCallRequest } from './queue.types';

const signaling = NucleusSignaling.getInstance();

const CallRequestLabel = styled.div`
  border-radius: 5px;
  border: 1px solid #82ceff;
  background: #82ceff;
  padding: 10px;
`;
const CallRequestLabelTitle = styled.div`
  color: #0a313f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: row;
`;

interface ProviderCallConfiguration {
  AudioCallsEnabled: boolean;
}
interface ProviderCallResponse {
  data: ProviderCallConfiguration;
}

const ProviderQueue = () => {
  const dispatch = useDispatch();
  const {
    actions,
    state: { queue: callRequestQueue },
  } = useNucleusProviders();
  const [markAsHandledData, setMarkAsHandledData] = useState<ProviderCallRequest | null>(null);
  const [handledCallNotes, setHandledCallNotes] = useState<string>('');

  const [postCallLogId, setPostCallLogId] = useState<string | null>(null);

  const providerId = AuthStore.getProviderID();

  const { closeModal, isOpen, openModal } = useModal();

  const [providerRoleName, setProviderRoleName] = useState<string>('');

  const { isOpen: isCallLogModalOpen, openModal: openCallLogModal, closeModal: closeCallLogModal } = useModal();

  const [audioCallsEnabled, setAudioCallsEnabled] = useState<boolean>(false);

  const onOpenCallLogModal = () => {
    openCallLogModal();
  };
  const onCloseCallLogModal = () => {
    setPostCallLogId(null);
    closeCallLogModal();
  };

  const refreshQueue = useCallback(() => {
    if (providerId) actions.fetchQueueForProvider(providerId);
  }, [providerId]);

  const onPostCall = args => {
    console.warn('onPostCall', args);
    console.warn('onPostCall args?.callLogID', args?.callLogID);
    console.warn('onPostCall args?.CallLogID', args?.CallLogID);
    const callLogId = args?.callLogID ?? args?.CallLogID;
    if (args?.ok && callLogId) {
      setPostCallLogId(callLogId);
    }
  };

  const getProviderCallRequestConfig = async () => {
    try {
      const response: ProviderCallResponse = await httpApi.get(`/providers/${providerId}/call_request_config`);
      const { data } = response;
      setAudioCallsEnabled(data.AudioCallsEnabled);
    } catch (error) {
      console.log('Error getting Provider call configuration: ', error);
    }
  };

  useEffect(() => {
    careCommunicationStore.addListener(CommunicationEvent.OnReportCallEnded, refreshQueue);
    careCommunicationStore.addListener(CommunicationEvent.OnReportCallHandled, refreshQueue);
    careCommunicationStore.addListener(CommunicationEvent.OnReportCallEnded, onPostCall);
    return () => {
      careCommunicationStore.removeListener(CommunicationEvent.OnReportCallEnded, refreshQueue);
      careCommunicationStore.removeListener(CommunicationEvent.OnReportCallHandled, refreshQueue);
      careCommunicationStore.removeListener(CommunicationEvent.OnReportCallEnded, onPostCall);
    };
  }, []);

  useEffect(() => {
    async function fetchProviderStrings() {
      if (!providerId) return;
      const { UserRoleName } = await getProviderStrings(providerId);
      setProviderRoleName(UserRoleName);
    }

    fetchProviderStrings();
    getProviderCallRequestConfig();
    refreshQueue();
  }, [providerId]);

  const handleCallStart = (callRequest: ProviderCallRequest, type: CallType) => {
    console.log('handleCallStart', callRequest);
    const callerName = `${providerRoleName} is calling you`;
    // We are sending the RequestedByUserID instead of PatientID and DeviceID to support the overnight app

    const patientName = callRequest.PatientFirstName + ' ' + callRequest.PatientLastName;
    const callTypeIfNotBargeIn = type;
    const callType = AuthStore.nucleusAutomaticCallRequestBargeIn ? CallType.BargeIn : callTypeIfNotBargeIn;
    const entityId = (callRequest.RequestedByUserID ? callRequest.RequestedByUserID : callRequest.PatientID).toLowerCase();
    const patientId = (callRequest.PatientID ? callRequest.PatientID : '').toLowerCase();
    const deviceId = (callRequest.RequestedByUserID ? callRequest.RequestedByUserID : callRequest.DeviceID).toLowerCase();
    const participants: FullCallEntity[] = [
      {
        devicesIds: [deviceId],
        name: patientName,
        entityId,
        host: false,
        status: 'connected',
        type: callType,
      },
    ];

    const callData: WebRTCCall = {
      requestId: callRequest.RequestID,
      patientId: patientId.toLowerCase(),
      callingMethod: callRequest.RequestedByUserID ? CallingMethods.Notify : CallingMethods.Call,
      participants,
      multiCall: false,
      entityId,
      patientFirstName: callRequest.PatientFirstName,
      patientLastName: callRequest.PatientLastName,
      calleeFirstName: callRequest.PatientFirstName,
      calleeLastName: callRequest.PatientLastName,
      patientName,
      deviceName: callRequest.DeviceName,
      type: callType,
      patientThumb: '',
      callerName,
    };

    console.log('Provider callData', callData);
    dispatch(startCall(callData));
  };

  const notifyCallHandledWithSignaling = async (callRequest: ProviderCallRequest) => {
    const data = JSON.stringify({
      type: 'MarkAsHandled',
    });
    signaling.sendUserDataMessageToPeer(new SignalingId(callRequest?.RequestedByUserID?.toLocaleLowerCase()), data);
  };

  const handleMarkAsHandled = async () => {
    console.log('handleMarkAsHandled', handledCallNotes, markAsHandledData?.RequestID, AuthStore.getUser().ID);

    await CareQueueStore.reportCallHandled({
      Notes: handledCallNotes,
      RequestID: markAsHandledData?.RequestID,
      UserID: AuthStore.getUser().ID,
    });
    await notifyCallHandledWithSignaling(markAsHandledData!);
    actions.fetchQueueForProvider(providerId!);
    closeModal();
  };

  return (
    <UINucleusContainer>
      <UINucleusPageTitle>Call Queue</UINucleusPageTitle>
      <UINucleusContentContainer style={{ marginBottom: '6%' }}>
        <CallRequestLabel>
          <CallRequestLabelTitle>Call Request</CallRequestLabelTitle>
        </CallRequestLabel>

        {callRequestQueue.map(callRequest => {
          return (
            <ProviderQueueRow
              key={callRequest.RequestID}
              callRequest={callRequest}
              markAsHandled={() => {
                setMarkAsHandledData(callRequest);
                openModal();
              }}
              onClick={(type: CallType) => {
                handleCallStart(callRequest, type);
              }}
              audioCallsEnabled={audioCallsEnabled}
            />
          );
        })}
      </UINucleusContentContainer>

      <PostCallDataModal isOpen={isCallLogModalOpen} openModal={onOpenCallLogModal} closeModal={onCloseCallLogModal} callLogId={postCallLogId} />

      <UIModal
        close={closeModal}
        isOpen={isOpen}
        size="medium"
        title="Mark as Handled"
        actions={[
          {
            label: 'Cancel',
            onClick: () => closeModal(),
            disabled: false,
            buttonVariant: 'secondary',
          },
          {
            label: 'Mark as handled',
            onClick: () => handleMarkAsHandled(),
            disabled: false,
            buttonVariant: 'primary',
          },
        ]}
      >
        <MarkCallAsHandled setHandledCallNotes={setHandledCallNotes} markAsHandledData={markAsHandledData} />
      </UIModal>
    </UINucleusContainer>
  );
};

export default ProviderQueue;
