import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import classNames from 'classnames';
import { HandleCallPayload } from 'components/Communication/communication.types';
import TelephoneInputUnderline from 'components/UI/Inputs/TelephoneInputUnderlined';
import { CallingMethods } from 'components/WebRTCCallView';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { connect } from 'react-redux';
import Select from 'react-select';
import { AuthStore, GeneralStore, CarePatientStore, CareQueueStore, CareCommunicationStore } from 'stores';

import { CommunicationEvent } from 'stores/CareCommunicationStore';
import { JQuery } from 'types/jquery.types';
import Message from '../../utils/Message';
import BlueToggle from './components/BlueToggle';
import CarePatientDeviceItemRow from './components/CarePatientDeviceItemRow';
import { ModalRequestDetails } from './PatientProfileInfo/ModalRequestDetails';
import { PatientSettings } from './PatientProfileInfo/PatientSettings';

class PatientProfileInfo extends React.Component<
  {
    patientId: string;
    patientDeviceMac: string;
    patientZipCode: string;
    patientTimezone: string;
    patientTimezoneOffset: number;
    autoCallDeviceID: string;
    autoCallMode: string;
    autoVideoCall: boolean;
    patientFullName: string;
    patientFirstName: string;
    patientLastName: string;
    patientImageThumb?: string;
  },
  {
    patientID: string;
    patientDeviceMac: string;
    patientTimezone: string;
    patientTimezoneOffset: number;
    deviceMode: string;
    deviceModeButtonTitle: string;
    deviceModeChangeVisibility: string;
    deviceModeAssignVisibility: string;
    devicesArray: any[];
    loadingDevices: boolean;
    enableAccountAutoAnswer: boolean;
    enableAutoAnswer: boolean;
    patientDevices: any[];
    saveNoteBtnStyle: string;
    updated: boolean;
    timeStamp: number;
    patientAge: string;
    btnAnotherDeviceStyle: string;
    btnAddDeviceStyle: string;
    fromDateTimeLogs: Date | null;
    toDateTimeLogs: Date | null;
    fromDateTimeMsgs: Date | null;
    toDateTimeMsgs: Date | null;
    fromDateTimeNotes: Date | null;
    enableFamilyMemberAutoAnswer: boolean;
    toDateTimeNotes: Date | null;
    messageDateTime: Date | null;
    assignmentEditDateTime: Date | null;

    patientPendingRequestLogID: string;
    patientPendingRequest: string;
    patientPendingRequestIcon: string;
    patientCallLogs: any[];
    txtAnotherDeviceName: string;
    deleteMessageBtnStyle: string;
    saveEditMessageBtnStyle: string;
    selectedPatientMessageId: string;
    selectedMessageId: string;
    selectedMessageContent: string;
    selectedMessageEditable: boolean;
    selectedMessageDeletable: boolean;
    saveNewMessageStyle: string;
    reqID: string | null;
    reqDateTime: string | null;
    reqEmergency: string | null;
    reqStatus: string | null;
    reqHandledDateTime: string | null;
    reqHandler: string | null;
    reqEscalated: string | null;
    reqRequests: string | null;
    reqNotes: string | null;
    reqCallID: string | null;
    reqCallDuration: string | null;
    reqCallStatus: string | null;
    reqCallParticipants: any[];
    reqCallNotes: string | null;
    selectedAssignment: any;
    assignmentDetailsMode: number;
    editAssignmentBtnStyle: string;
    assignmentUpdateAction: boolean;
    deleteMemberBtnStyle: string;
    selectedMemberID: string;
    selectedDeviceMacAddress: string;
    selectedDeviceHexnodeID: string;
    selectedDeviceName: string;
    selectedDeviceLabel: string;
    selectedDeviceConcierge?: any;
    selectedDevicePhotoFrameMode: boolean;
    selectedDevicePhotoFrameModeNewValue: boolean;
    selectedDeviceVolume: number | null;
    selectedDeviceVolumeBarStyle: string;
    deviceModeEdit: string;
    deviceModeDelete: string;
    deviceModalOptionColor: string;
    deviceModalIOS?: boolean;
    editDeviceBtnStyle: string;
    deleteDeviceBtnStyle: string;
    assignmentCheckInAction: boolean;
    memberModalOptionLabel: string;
    errorMemberEmailVisibility: string;
    errorMemberTelephoneVisibility: string;
    deviceModalOptionLabel: string;
    memberModeEdit: string;
    assignmentCheckOutAction: boolean;
    memberModeDelete: string;
    memberModalOptionColor: string;
    selectedMemberFirstName: string;
    selectedMemberLastName: string;
    selectedMemberEmail: string;
    selectedMemberTelephone: string;
    selectedMemberAccountAutoAnswer?: boolean;
    selectedMemberAdmin?: boolean;
    selectedMemberAutoAnswer?: boolean;
    selectedMemberReceiveUpdates: string;
    selectedMemberProfileCreated: string;
    errorMemberTelephoneText: string;
    noteModalOptionLabel: string;
    messageModalOptionLabel: string;
    messageModeEdit: string;
    messageModeDelete: string;
    messageModalOptionColor: string;
    selectedDeviceToRemove?: string;
    selectedDeviceIdentifierTitle?: string | null;
    noteModeEdit: string;
    noteModeDelete: string;
    noteModalOptionColor: string;
    noteModalOptionStyle: string;
    editMemberBtnStyle: string;

    txtEditNoteState?: string;
    saveEditNoteBtnStyle?: string;
    deleteNoteBtnStyle?: string;
    selectedNoteId?: string;
    selectedNoteContent?: string;
    selectedNoteDateTime?: string;
    selectedNoteUser?: string;
    selectedNoteType?: string;

    deliveredMessagesTab: string;
    scheduledMessagesContent: string;
    deliveredMessagesContent: string;
  }
> {
  private dateOptions: Intl.DateTimeFormat | any;
  private timeOptions: Intl.DateTimeFormat | any;

  private controlTimezone: number;
  private newDeviceMacID: string;
  private newDeviceMacLabel: string;
  private newDevicePreassignedName: string;
  private memberTelephone: string;
  private txtAnotherDeviceName: React.RefObject<HTMLInputElement>;
  private txtDeviceName: React.RefObject<HTMLInputElement>;
  private deviceVolume: React.RefObject<HTMLInputElement>;
  private checkEnableCallShorcut: React.RefObject<HTMLInputElement>;
  private memberEmail: React.RefObject<HTMLInputElement>;
  private memberFirstName: React.RefObject<HTMLInputElement>;
  private memberLastName: React.RefObject<HTMLInputElement>;
  private textEditPatientNote: React.RefObject<HTMLInputElement>;
  private textEditPatientMessage: React.RefObject<HTMLInputElement>;
  private textMessageToPatient: React.RefObject<HTMLInputElement>;
  private txtSelectedDeviceLabel: React.RefObject<HTMLInputElement>;
  private assignmentCaregiver: React.RefObject<HTMLInputElement>;
  private assignmentPatient: React.RefObject<HTMLInputElement>;
  private textAssignmentEditMessage: React.RefObject<HTMLInputElement>;
  private assignmentEditDateTime: React.RefObject<HTMLInputElement>;
  private assignmentEditCheckIn: React.RefObject<HTMLInputElement>;
  private assignmentEditCheckOut: React.RefObject<HTMLInputElement>;
  private txtSelectedDeviceName: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      patientID: this.props.patientId,
      patientDevices: [],
      loadingDevices: false,
      patientDeviceMac: this.props.patientDeviceMac,
      patientTimezone: '',
      patientTimezoneOffset: 0,
      deviceMode: 'Info',
      deviceModeButtonTitle: 'Assign New',
      deviceModeChangeVisibility: ' hide',
      deviceModeAssignVisibility: ' hide',
      devicesArray: [],
      updated: false,
      timeStamp: Date.now(),
      patientAge: '',

      btnAnotherDeviceStyle: 'nucleus-submit-btn-small-disabled',
      btnAddDeviceStyle: 'nucleus-submit-btn-small-disabled',

      fromDateTimeLogs: null,
      toDateTimeLogs: null,

      fromDateTimeMsgs: null,
      toDateTimeMsgs: null,

      fromDateTimeNotes: null,
      toDateTimeNotes: null,

      messageDateTime: null,
      assignmentEditDateTime: null,

      patientPendingRequestLogID: '',
      patientPendingRequest: '',
      patientPendingRequestIcon: ' hide',
      patientCallLogs: [],

      reqID: null,
      reqDateTime: null,
      reqEmergency: null,
      reqStatus: null,
      reqHandledDateTime: null,
      reqHandler: null,
      reqEscalated: null,
      reqRequests: null,
      reqNotes: null,

      reqCallID: null,
      reqCallDuration: null,
      reqCallStatus: null,
      reqCallParticipants: [],
      reqCallNotes: null,

      saveNoteBtnStyle: 'nucleus-submit-btn',

      selectedAssignment: {},
      assignmentDetailsMode: 1,

      editAssignmentBtnStyle: 'nucleus-submit-btn',

      assignmentUpdateAction: false,
      assignmentCheckInAction: false,
      assignmentCheckOutAction: false,

      selectedMemberID: '',
      selectedMemberFirstName: '',
      selectedMemberLastName: '',
      selectedMemberEmail: '',
      selectedMemberTelephone: '',
      selectedMemberReceiveUpdates: '',
      selectedMemberProfileCreated: '',
      selectedMemberAccountAutoAnswer: null,
      selectedMemberAutoAnswer: null,
      selectedMemberAdmin: false,

      memberModalOptionLabel: 'Delete',
      memberModeEdit: '',
      memberModeDelete: 'hide',
      memberModalOptionColor: 'txt-red',

      editMemberBtnStyle: 'nucleus-submit-btn',
      deleteMemberBtnStyle: 'nucleus-submit-btn',

      errorMemberEmailVisibility: 'hide',
      errorMemberTelephoneVisibility: 'hide',
      errorMemberTelephoneText: 'Telephone already registered. ',

      saveNewMessageStyle: 'nucleus-submit-btn-disabled',

      noteModalOptionLabel: 'Delete',
      noteModeEdit: '',
      noteModeDelete: 'hide',
      noteModalOptionColor: 'txt-red',
      noteModalOptionStyle: '',

      txtEditNoteState: '',
      saveEditNoteBtnStyle: 'nucleus-submit-btn',
      deleteNoteBtnStyle: 'nucleus-submit-btn',
      selectedNoteId: '',
      selectedNoteContent: '',
      selectedNoteDateTime: '',
      selectedNoteUser: '',
      selectedNoteType: '',

      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',

      saveEditMessageBtnStyle: 'nucleus-submit-btn',
      deleteMessageBtnStyle: 'nucleus-submit-btn',
      selectedPatientMessageId: '',
      selectedMessageId: '',
      selectedMessageContent: '',
      selectedMessageEditable: true,
      selectedMessageDeletable: false,

      //Messages Tab

      deliveredMessagesTab: '',

      scheduledMessagesContent: ' ',
      deliveredMessagesContent: ' hide',

      // Call
      callMicValue: 0.5,
      calVolValue: 50,
      btnControlMuteSrc: '/img/call_mute_control.png',
      btnControlNightSrc: '/img/call_night_vision_control.png',
      callFrameVisibility: ' hide',
      callingFrameVisibility: ' ',
      currentCallMode: 'Audio Call',
      callControlsVisibility: ' hide',
      controlsVisibility: ' hide',

      nightModeVisibility: ' hide',
      endCallBtnSrc: '/img/endVideoCall.png',

      shouldMute: false,

      audioring1: ' hide',
      audioring2: ' hide',
      audioring3: ' hide',
      audioring4: ' hide',
      // End Call

      selectedDeviceToRemove: '',
      selectedDeviceIdentifierTitle: 'Device Mac',
      selectedDeviceMacAddress: '',
      selectedDeviceHexnodeID: '',
      selectedDeviceName: '',
      selectedDevicePhotoFrameMode: false,
      selectedDevicePhotoFrameModeNewValue: false,
      selectedDeviceVolume: 0,
      selectedDeviceVolumeBarStyle: 'deviceVolume',
      selectedDeviceConcierge: false,
      //deviceConciergeValue: "",
      deviceModalOptionLabel: 'Remove',
      deviceModalIOS: false,
      deviceModeEdit: '',
      deviceModeDelete: 'hide',
      deviceModalOptionColor: 'txt-red',

      editDeviceBtnStyle: 'nucleus-submit-btn',
      deleteDeviceBtnStyle: 'nucleus-submit-btn',
      txtAnotherDeviceName: '',

      btnResendMemberEmailStyle: 'nucleus-submit-btn-disabled',

      moveFamilyModalOpen: false,
    };

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.handlePanelAssignDevice = this.handlePanelAssignDevice.bind(this);
    this.handleInitPanelAssignDevice = this.handleInitPanelAssignDevice.bind(this);

    this.handleAssignDevice = this.handleAssignDevice.bind(this);
    this.handleAssignAnotherDevice = this.handleAssignAnotherDevice.bind(this);
    this.onGetDevicesAction = this.onGetDevicesAction.bind(this);
    this.onAssignDevicePatientAction = this.onAssignDevicePatientAction.bind(this);

    this.onCallEndedReportedAction = this.onCallEndedReportedAction.bind(this);

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleRestartDevice = this.handleRestartDevice.bind(this);
    this.handleVolumeMouseUp = this.handleVolumeMouseUp.bind(this);

    this.handleNewMessageToPatient = this.handleNewMessageToPatient.bind(this);

    console.log('PATIENT MAC !!!!!!!', this.props.patientDeviceMac);

    //RequestCallLogNotes

    this.onGetCallRequestLogDetailsAction = this.onGetCallRequestLogDetailsAction.bind(this);
    this.handleCloseRequestDetailsModal = this.handleCloseRequestDetailsModal.bind(this);

    this.handleSaveRequestNotes = this.handleSaveRequestNotes.bind(this);
    this.onUpdateCallRequestLogNotesAction = this.onUpdateCallRequestLogNotesAction.bind(this);

    this.onOpenPatientAssignmentModalAction = this.onOpenPatientAssignmentModalAction.bind(this);
    this.handleCloseEditAssignmentModal = this.handleCloseEditAssignmentModal.bind(this);
    this.handleEditAssignmentMessage = this.handleEditAssignmentMessage.bind(this);
    this.handleSubmitEditAssignment = this.handleSubmitEditAssignment.bind(this);
    this.onAssignmentUpdatedAction = this.onAssignmentUpdatedAction.bind(this);

    this.onOpenFamilyMemberModalAction = this.onOpenFamilyMemberModalAction.bind(this);

    this.handleCloseEditMemberModal = this.handleCloseEditMemberModal.bind(this);

    this.handleMemberModalMode = this.handleMemberModalMode.bind(this);
    this.handleSubmitDeleteMember = this.handleSubmitDeleteMember.bind(this);
    this.handleSubmitEditMember = this.handleSubmitEditMember.bind(this);
    this.onUpdateFamilyMemberAction = this.onUpdateFamilyMemberAction.bind(this);
    this.onUpdateFamilyMemberNameAction = this.onUpdateFamilyMemberNameAction.bind(this);
    this.onUpdateFamilyMemberTelephoneAction = this.onUpdateFamilyMemberTelephoneAction.bind(this);

    this.handleEnableMemberAutoAnswer = this.handleEnableMemberAutoAnswer.bind(this);
    this.onEnableFamilyMemberAutoAnswerUpdatedAction = this.onEnableFamilyMemberAutoAnswerUpdatedAction.bind(this);

    this.handleEnableMemberAdmin = this.handleEnableMemberAdmin.bind(this);
    this.onFamilyMemberAdminUpdatedAction = this.onFamilyMemberAdminUpdatedAction.bind(this);

    this.validateTxtMemberInput = this.validateTxtMemberInput.bind(this);
    this.onDeleteFamilyMemberAction = this.onDeleteFamilyMemberAction.bind(this);

    //Notes
    this.onOpenEditNotesModalAction = this.onOpenEditNotesModalAction.bind(this);
    this.handleCloseEditNoteModal = this.handleCloseEditNoteModal.bind(this);
    this.handleEditNoteModalMode = this.handleEditNoteModalMode.bind(this);
    this.validateEditNoteText = this.validateEditNoteText.bind(this);

    this.handleSaveEditNote = this.handleSaveEditNote.bind(this);
    this.handleSubmitDeleteNote = this.handleSubmitDeleteNote.bind(this);
    this.onUpdatePatientNoteAction = this.onUpdatePatientNoteAction.bind(this);
    this.onDeletePatientNoteAction = this.onDeletePatientNoteAction.bind(this);

    this.onUpdateCallLogNotesAction = this.onUpdateCallLogNotesAction.bind(this);

    //Message
    this.onOpenEditMessageModalAction = this.onOpenEditMessageModalAction.bind(this);
    this.handleCloseEditMessageModal = this.handleCloseEditMessageModal.bind(this);
    this.handleEditMessageModalMode = this.handleEditMessageModalMode.bind(this);
    this.validateEditMessageText = this.validateEditMessageText.bind(this);

    this.handleSaveEditMessage = this.handleSaveEditMessage.bind(this);
    this.handleSubmitDeleteMessage = this.handleSubmitDeleteMessage.bind(this);
    this.onUpdatePatientMessageAction = this.onUpdatePatientMessageAction.bind(this);
    this.onDeletePatientMessageAction = this.onDeletePatientMessageAction.bind(this);

    this.refreshDevices = this.refreshDevices.bind(this);

    this.onPatientGetDataAction = this.onPatientGetDataAction.bind(this);
    this.onGetPatientDevicesAction = this.onGetPatientDevicesAction.bind(this);

    this.testFunction = this.testFunction.bind(this);

    //Mesage to Device
    this.handleCloseNewMessageModal = this.handleCloseNewMessageModal.bind(this);
    this.validateNewMessageText = this.validateNewMessageText.bind(this);
    this.handleSaveNewMessage = this.handleSaveNewMessage.bind(this);
    this.onMessageToPatientSentAction = this.onMessageToPatientSentAction.bind(this);

    this.onHandleRefreshAction = this.onHandleRefreshAction.bind(this);
    this.onHandleAudioCallAction = this.onHandleAudioCallAction.bind(this);
    this.onHandleVideoCallAction = this.onHandleVideoCallAction.bind(this);
    this.onHandleBargeCallAction = this.onHandleBargeCallAction.bind(this);
    this.onHandleSilentBargeCallAction = this.onHandleSilentBargeCallAction.bind(this);
    // DEPRECATED Dic 2017 - CARE-1895
    //this.onNewMessageToDeviceAction = this.onNewMessageToDeviceAction.bind(this);

    this.onOpenEditPatientDeviceModalAction = this.onOpenEditPatientDeviceModalAction.bind(this);
    this.handleCancelUnassignDevice = this.handleCancelUnassignDevice.bind(this);

    this.onRequestRestartDeviceAction = this.onRequestRestartDeviceAction.bind(this);

    this.handleDeviceModalMode = this.handleDeviceModalMode.bind(this);
    this.handleUnassignDevice = this.handleUnassignDevice.bind(this);

    this.handleSubmitEditDevice = this.handleSubmitEditDevice.bind(this);
    this.onDeviceUpdatedAction = this.onDeviceUpdatedAction.bind(this);
    this.requestDeviceReboot = this.requestDeviceReboot.bind(this);
    this.onRebootDeviceRequestedAction = this.onRebootDeviceRequestedAction.bind(this);

    this.handleAddAnotherDeviceName = this.handleAddAnotherDeviceName.bind(this);
    this.handleAddDeviceName = this.handleAddDeviceName.bind(this);
    this.handleSelectNewDeviceMac = this.handleSelectNewDeviceMac.bind(this);
    this.handleSelectAnotherDeviceMac = this.handleSelectAnotherDeviceMac.bind(this);
    this.handleSelectDeviceOpens = this.handleSelectDeviceOpens.bind(this);

    this.handleCheckEditDeviceName = this.handleCheckEditDeviceName.bind(this);

    this.handleResendMemberEmail = this.handleResendMemberEmail.bind(this);
    this.onResendInviteAction = this.onResendInviteAction.bind(this);

    this.onDeviceVolumeUpdatedAction = this.onDeviceVolumeUpdatedAction.bind(this);

    this.onGetQueueSizeAction = this.onGetQueueSizeAction.bind(this);

    this.autoStartCall = this.autoStartCall.bind(this);

    this.newDeviceMacID = '';
    this.newDeviceMacLabel = '';
    this.newDevicePreassignedName = '';

    this.controlTimezone = 0;

    console.log('PatientProfileInfo PROPS', this.props.autoCallDeviceID);
    this.txtAnotherDeviceName = React.createRef();
    this.txtDeviceName = React.createRef();
    this.deviceVolume = React.createRef();
    this.checkEnableCallShorcut = React.createRef();
    this.memberEmail = React.createRef();
    this.memberFirstName = React.createRef();
    this.textEditPatientNote = React.createRef();
    this.memberLastName = React.createRef();
    this.textEditPatientMessage = React.createRef();
    this.textMessageToPatient = React.createRef();
    this.txtSelectedDeviceLabel = React.createRef();
    this.assignmentCaregiver = React.createRef();
    this.assignmentPatient = React.createRef();
    this.textAssignmentEditMessage = React.createRef();
    this.assignmentEditDateTime = React.createRef();
    this.assignmentEditCheckIn = React.createRef();
    this.assignmentEditCheckOut = React.createRef();
    this.txtSelectedDeviceName = React.createRef();
  }

  componentDidMount() {
    //Register Listener

    CarePatientStore.on('onGetDevicesAction', this.onGetDevicesAction);

    CarePatientStore.on('onAssignDevicePatientAction', this.onAssignDevicePatientAction);
    CareCommunicationStore.on(CommunicationEvent.OnCallEndedReported, this.onCallEndedReportedAction);

    CarePatientStore.on('onGetCallRequestLogDetails', this.onGetCallRequestLogDetailsAction);
    CarePatientStore.on('onUpdateCallRequestLogNotes', this.onUpdateCallRequestLogNotesAction);

    CarePatientStore.on('onPatientFamilyDeleted', this.onPatientFamilyMembersDeleted);

    CarePatientStore.on('onOpenPatientAssignmentModal', this.onOpenPatientAssignmentModalAction);
    CarePatientStore.on('onAssignmentUpdated', this.onAssignmentUpdatedAction);

    //CaregiverStore.on('onHasAllCaregiversData', this.onHasAllCaregiversDataAction);
    GeneralStore.on('onOpenFamilyMemberModal', this.onOpenFamilyMemberModalAction);

    CarePatientStore.on('onUpdateFamilyMember', this.onUpdateFamilyMemberAction);
    CarePatientStore.on('onUpdateFamilyMemberName', this.onUpdateFamilyMemberNameAction);
    CarePatientStore.on('onUpdateFamilyMemberTelephone', this.onUpdateFamilyMemberTelephoneAction);
    CarePatientStore.on('onEnableFamilyMemberAutoAnswerUpdated', this.onEnableFamilyMemberAutoAnswerUpdatedAction);
    CarePatientStore.on('onFamilyMemberAdminUpdated', this.onFamilyMemberAdminUpdatedAction);

    CarePatientStore.on('onDeleteFamilyMember', this.onDeleteFamilyMemberAction);

    GeneralStore.on('onOpenEditNotesModal', this.onOpenEditNotesModalAction);
    CarePatientStore.on('onUpdatePatientNote', this.onUpdatePatientNoteAction);

    CarePatientStore.on('onUpdateCallLogNotesAction', this.onUpdateCallLogNotesAction);

    CarePatientStore.removeListener('onPatientFamilyDeleted', this.onPatientFamilyMembersDeleted);

    CarePatientStore.on('onDeletePatientNote', this.onDeletePatientNoteAction);

    GeneralStore.on('onOpenEditMessageModal', this.onOpenEditMessageModalAction);
    CarePatientStore.on('onUpdatePatientMessage', this.onUpdatePatientMessageAction);
    CarePatientStore.on('onDeletePatientMessage', this.onDeletePatientMessageAction);

    CarePatientStore.on('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.on('onGetPatientDevices', this.onGetPatientDevicesAction);

    CarePatientStore.on('onMessageToPatientSent', this.onMessageToPatientSentAction);

    CarePatientStore.on('onHandleRefresh', this.onHandleRefreshAction);
    CarePatientStore.on('onHandleAudioCall', this.onHandleAudioCallAction);
    CarePatientStore.on('onHandleVideoCall', this.onHandleVideoCallAction);
    CarePatientStore.on('onHandleBargeCall', this.onHandleBargeCallAction);
    CarePatientStore.on('onHandleSilentBargeCall', this.onHandleSilentBargeCallAction);
    // DEPRECATED Dic 2017 - CARE-1895
    //CarePatientStore.on("onNewMessageToDevice", this.onNewMessageToDeviceAction);
    CarePatientStore.on('onDeviceUpdated', this.onDeviceUpdatedAction);
    CarePatientStore.on('onOpenEditPatientDeviceModal', this.onOpenEditPatientDeviceModalAction);

    CarePatientStore.on('onRequestRestartDevice', this.onRequestRestartDeviceAction);
    CarePatientStore.on('onRebootDeviceRequested', this.onRebootDeviceRequestedAction);

    CarePatientStore.on('onResendInvite', this.onResendInviteAction);

    CarePatientStore.on('onDeviceVolumeUpdated', this.onDeviceVolumeUpdatedAction);

    //DEPRECATED: November 2nd 2021
    //CarePatientStore.on("onSelectedDeviceConciergeUpdated", this.onSelectedDeviceConciergeUpdatedAction);

    CarePatientStore.on('onSelectedDeviceCallShortcutUpdated', this.onSelectedDeviceCallShortcutUpdatedAction);

    CareQueueStore.on('onGetQueueSize', this.onGetQueueSizeAction);

    /*this.setState({
			patientDeviceMac : this.props.patientDeviceMac
		});
		*/
    CarePatientStore.getCareDevicesWarming({
      AccountID: AuthStore.getUserAccountID(),
    });

    CarePatientStore.getPatientDevices({
      PatientID: this.state.patientID,
    });
    // DEPRECATED: We removed the caregivers functionality
    // CarePatientStore.getPatientAssignments({
    // 	PatientID: this.state.patientID,
    // 	ShowAll: true,
    // });

    ($('.tooltipped') as unknown as JQuery).tooltip({ delay: 50 });

    const pendingRequestOnQueue = CareQueueStore.getPendingRequestByPatient(this.props.patientId, '');
    //console.log("Pending REQUEST: ", pendingRequestOnQueue);
    if (pendingRequestOnQueue.length > 0) {
      this.setState({
        patientPendingRequest: pendingRequestOnQueue,
        patientPendingRequestIcon: ' ',
      });
    }

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      };
    }

    this.handleInitPanelAssignDevice();

    const currentDate = new Date();
    const passedDate = new Date();

    currentDate.setHours(currentDate.getHours(), currentDate.getMinutes() + 30, 0);

    passedDate.setDate(passedDate.getDate() - 7);
    passedDate.setHours(8, 0, 0);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    this.controlTimezone = currentTimezone;
    console.log('CONTROL TIMEZONE:', this.controlTimezone);

    this.setState({
      loadingDevices: true,
      fromDateTimeLogs: passedDate,
      toDateTimeLogs: currentDate,

      fromDateTimeMsgs: passedDate,
      toDateTimeMsgs: currentDate,

      fromDateTimeNotes: passedDate,
      toDateTimeNotes: currentDate,
    });

    passedDate.setHours(0);
    passedDate.setMinutes(0);
    passedDate.setSeconds(0);
    passedDate.setHours(passedDate.getHours() + this.controlTimezone * -1);

    currentDate.setHours(23);
    currentDate.setMinutes(59);
    currentDate.setSeconds(0);
    currentDate.setHours(currentDate.getHours() + this.controlTimezone * -1);

    console.log('PatientProfileInfo.js', 'fromDate ' + passedDate);
    console.log('PatientProfileInfo.js', 'toDate ' + currentDate);

    console.log('PatientProfileInfo PROPS 2, autoCallDeviceID', this.props.autoCallDeviceID);
    console.log('PatientProfileInfo PROPS 2, autoCallMode', this.props.autoCallMode);

    if (this.props.autoVideoCall) {
      setTimeout(() => {
        this.autoVideoCallAllDevices();
      }, 2000);
    } else if (this.props.autoCallDeviceID !== '') {
      setTimeout(() => {
        this.autoStartCall(this.props.autoCallMode);
      }, 2000);
    }
  }

  componentWillUnmount() {
    //Remove Listener
    CarePatientStore.removeListener('onGetDevicesAction', this.onGetDevicesAction);
    CarePatientStore.removeListener('onAssignDevicePatientAction', this.onAssignDevicePatientAction);

    CareCommunicationStore.removeListener('onCallEndedReported', this.onCallEndedReportedAction);

    CarePatientStore.removeListener('onGetCallRequestLogDetails', this.onGetCallRequestLogDetailsAction);
    CarePatientStore.removeListener('onUpdateCallRequestLogNotes', this.onUpdateCallRequestLogNotesAction);

    CarePatientStore.removeListener('onOpenPatientAssignmentModal', this.onOpenPatientAssignmentModalAction);
    CarePatientStore.removeListener('onAssignmentUpdated', this.onAssignmentUpdatedAction);

    //CaregiverStore.removeListener('onHasAllCaregiversData', this.onHasAllCaregiversDataAction);
    GeneralStore.removeListener('onOpenFamilyMemberModal', this.onOpenFamilyMemberModalAction);

    CarePatientStore.removeListener('onUpdateFamilyMember', this.onUpdateFamilyMemberAction);
    CarePatientStore.removeListener('onUpdateFamilyMemberName', this.onUpdateFamilyMemberNameAction);
    CarePatientStore.removeListener('onUpdateFamilyMemberTelephone', this.onUpdateFamilyMemberTelephoneAction);
    CarePatientStore.removeListener('onEnableFamilyMemberAutoAnswerUpdated', this.onEnableFamilyMemberAutoAnswerUpdatedAction);
    CarePatientStore.removeListener('onFamilyMemberAdminUpdated', this.onFamilyMemberAdminUpdatedAction);

    CarePatientStore.removeListener('onDeleteFamilyMember', this.onDeleteFamilyMemberAction);

    GeneralStore.removeListener('onOpenEditNotesModal', this.onOpenEditNotesModalAction);
    CarePatientStore.removeListener('onUpdatePatientNote', this.onUpdatePatientNoteAction);

    CarePatientStore.removeListener('onUpdateCallLogNotesAction', this.onUpdateCallLogNotesAction);

    CarePatientStore.removeListener('onDeletePatientNote', this.onDeletePatientNoteAction);

    GeneralStore.removeListener('onOpenEditMessageModal', this.onOpenEditMessageModalAction);
    CarePatientStore.removeListener('onUpdatePatientMessage', this.onUpdatePatientMessageAction);
    CarePatientStore.removeListener('onDeletePatientMessage', this.onDeletePatientMessageAction);

    CarePatientStore.removeListener('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.removeListener('onGetPatientDevices', this.onGetPatientDevicesAction);

    CarePatientStore.removeListener('onMessageToPatientSent', this.onMessageToPatientSentAction);

    CarePatientStore.removeListener('onHandleRefresh', this.onHandleRefreshAction);
    CarePatientStore.removeListener('onHandleAudioCall', this.onHandleAudioCallAction);
    CarePatientStore.removeListener('onHandleVideoCall', this.onHandleVideoCallAction);
    CarePatientStore.removeListener('onHandleBargeCall', this.onHandleBargeCallAction);
    CarePatientStore.removeListener('onHandleSilentBargeCall', this.onHandleSilentBargeCallAction);
    // DEPRECATED Dic 2017 - CARE-1895
    //CarePatientStore.removeListener("onNewMessageToDevice", this.onNewMessageToDeviceAction);

    CarePatientStore.removeListener('onDeviceUpdated', this.onDeviceUpdatedAction);

    CarePatientStore.removeListener('onOpenEditPatientDeviceModal', this.onOpenEditPatientDeviceModalAction);
    CarePatientStore.removeListener('onRebootDeviceRequested', this.onRebootDeviceRequestedAction);
    CarePatientStore.removeListener('onRequestRestartDevice', this.onRequestRestartDeviceAction);

    CarePatientStore.removeListener('onResendInvite', this.onResendInviteAction);

    CarePatientStore.removeListener('onDeviceVolumeUpdated', this.onDeviceVolumeUpdatedAction);

    //DEPRECATED: November 2nd 2021
    //CarePatientStore.removeListener("onSelectedDeviceConciergeUpdated", this.onSelectedDeviceConciergeUpdatedAction);

    CarePatientStore.removeListener('onSelectedDeviceCallShortcutUpdated', this.onSelectedDeviceCallShortcutUpdatedAction);

    CareQueueStore.removeListener('onGetQueueSize', this.onGetQueueSizeAction);

    ($('.tooltipped') as unknown as JQuery).tooltip('remove');
  }

  handleInitPanelAssignDevice() {
    if (this.state.deviceModeButtonTitle == 'Assign New') {
      this.setState({
        deviceModeButtonTitle: 'Assign New',
        deviceModeChangeVisibility: ' ',
        deviceModeAssignVisibility: ' hide',
      });
    } else if (this.state.deviceModeButtonTitle == 'Cancel') {
      this.setState({
        deviceModeButtonTitle: 'Cancel',
        deviceModeChangeVisibility: ' hide',
        deviceModeAssignVisibility: ' ',
      });
    }
  }

  handlePanelAssignDevice() {
    console.log('handlePanelAssignDevice');
    if (this.state.deviceModeButtonTitle == 'Assign New') {
      this.setState({
        devicesArray: [],

        deviceModeButtonTitle: 'Cancel',
        deviceModeChangeVisibility: ' hide',
        deviceModeAssignVisibility: ' ',

        btnAddDeviceStyle: 'nucleus-submit-btn-small-disabled',
        btnAnotherDeviceStyle: 'nucleus-submit-btn-small-disabled',
      });

      //this.refs.selectAnotherDeviceMacAddress.value = "";
      this.txtAnotherDeviceName.current.value = '';

      //this.refs.selectDeviceMacAddress.value = "";
      this.txtDeviceName.current.value = '';

      this.newDeviceMacID = '';
      this.newDeviceMacLabel = '';
      this.newDevicePreassignedName = '';

      setTimeout(() => {
        this.txtAnotherDeviceName.current.focus();
        this.txtDeviceName.current.focus();
      }, 100);
    } else if (this.state.deviceModeButtonTitle == 'Cancel') {
      this.setState({
        deviceModeButtonTitle: 'Assign New',
        deviceModeChangeVisibility: ' ',
        deviceModeAssignVisibility: ' hide',
      });
    }
  }

  handleAssignDevice() {
    console.log('handleAssignDevice');
    console.log('handleAssignDevice ID', this.newDeviceMacID);
    console.log('handleAssignDevice MAC', this.newDeviceMacLabel);
    console.log('handleAssignDevice PreAssignedName', this.newDevicePreassignedName);

    CarePatientStore.assignDeviceToPatient({
      PatientID: this.props.patientId,
      DeviceID: this.newDeviceMacID,
      DeviceName: this.txtDeviceName.current.value,
    });

    this.newDevicePreassignedName = this.txtDeviceName.current.value;
    this.setState({
      //newDeviceMacID : this.refs.selectAnotherDeviceMacAddress.selectedOptions[0].text
      btnAddDeviceStyle: 'nucleus-submit-btn-small-disabled',
      txtAnotherDeviceName: '',
    });
  }

  handleAssignAnotherDevice() {
    console.log('handleAssignAnotherDevice');
    console.log('handleAssignAnotherDevice ID', this.newDeviceMacID);
    console.log('handleAssignAnotherDevice MAC', this.newDeviceMacLabel);

    CarePatientStore.assignDeviceToPatient({
      PatientID: this.props.patientId,
      DeviceID: this.newDeviceMacID,
      DeviceName: this.txtAnotherDeviceName.current.value,
    });
    this.newDevicePreassignedName = this.txtAnotherDeviceName.current.value;
    this.setState({
      //newDeviceMacID : this.refs.selectAnotherDeviceMacAddress.selectedOptions[0].text
      btnAnotherDeviceStyle: 'nucleus-submit-btn-small-disabled',
      txtAnotherDeviceName: '',
    });
  }

  onGetDevicesAction(response) {
    console.log('onGetDevicesAction', response);

    if (response.ok) {
      this.setState({
        devicesArray: [],
      });

      let availableDevices = [].concat(response.devices);
      availableDevices = availableDevices.sort((a, b) => {
        let textA = '';
        if (a.PreAssignedName) {
          textA = a.PreAssignedName.toLowerCase();
        } else if (a.Mac) {
          textA = a.Mac.toLowerCase();
        } else if (a.HexnodeID) {
          textA = a.HexnodeID.toLowerCase();
        }

        let textB = '';
        if (b.PreAssignedName) {
          textB = b.PreAssignedName.toLowerCase();
        } else if (b.Mac) {
          textB = b.Mac.toLowerCase();
        } else if (b.HexnodeID) {
          textB = b.HexnodeID.toLowerCase();
        }

        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      setTimeout(() => {
        this.setState({
          devicesArray: availableDevices,
        });
      }, 100);
    } else {
      Message.show('There was a problem getting the available devices, please try again.');
    }
  }

  onAssignDevicePatientAction(response) {
    console.log('onAssignDevicePatientAction');

    if (response.ok) {
      CarePatientStore.getPatientDevices({
        PatientID: this.props.patientId,
      });
      CarePatientStore.getCareDevices({
        AccountID: AuthStore.getUserAccountID(),
      });

      this.setState({
        patientDeviceMac: this.newDeviceMacLabel, // this.refs.selectDeviceMacAddress.selectedOptions[0].text,
        updated: true,
        btnAddDeviceStyle: 'nucleus-submit-btn-small',
        btnAnotherDeviceStyle: 'nucleus-submit-btn-small',
        loadingDevices: true,
      });
      this.handlePanelAssignDevice();
      Message.show("Device '" + this.newDevicePreassignedName + "' was successfully assigned to " + this.props.patientFullName + '.');
      //this.refs.selectAnotherDeviceName.value = ""
      //this.selectAnotherDeviceName.state.value.value = ""
    } else {
      Message.show('There was a problem getting the available devices, please try again.');
    }
  }

  onUnassignDevicePatientAction2(response) {
    console.log('onUnassignDevicePatientAction2', response);
  }

  onCallEndedReportedAction(response) {
    console.log('Uhu!!!', response, this.props.patientId);
    if (response.patientID == this.props.patientId) {
      const pendingRequestOnQueue = CareQueueStore.getPendingRequestByPatient(this.props.patientId, '');

      this.setState({
        patientPendingRequest: pendingRequestOnQueue,
        //patientPendingRequestIcon : (pendingRequestOnQueue.length > 0) ? ' ' :' hide' ,
        patientPendingRequestLogID: '',
      });
      console.log('Uhu!!! 2');
    }
  }

  // Edit Assignment
  validateAssignmentDate(date, obj) {
    const checkInDate = this.assignmentEditCheckIn.current.value;

    if (new Date(checkInDate) < new Date(date)) {
      /*
			obj.addClass("invalid-field");
			// alert("CheckIn date should be greater than the Assignment Date");
			Materialize.toast('CheckIn date should be greater than the Assignment Date', 4000) // 4000 is the duration of the toast
			this.setState({
				editAssignmentBtnStyle : 'nucleus-submit-btn-disabled'
			})
			return false;
			*/
    } else {
      console.log('removing class');
      obj.removeClass('invalid-field');
      this.setState({
        assignmentUpdateAction: true,
        editAssignmentBtnStyle: 'nucleus-submit-btn',
      });
      return true;
    }
  }

  validateCheckInDate(date, obj) {
    const AssignmentDate = this.assignmentEditDateTime.current.value;
    if (this.validateAssignmentDate(AssignmentDate, obj)) {
      const checkOutDate = this.assignmentEditCheckOut.current.value;

      if (new Date(checkOutDate) < new Date(date)) {
        obj.addClass('invalid-field');
        // alert("CheckIn date should be greater than the Assignment Date");
        this.setState({
          editAssignmentBtnStyle: 'nucleus-submit-btn-disabled',
        });
        //@ts-ignore
        Materialize.toast('CheckOut date should be greater than the CheckIn Date', 4000); // 4000 is the duration of the toast
        return false;
      } else {
        console.log('removing class');
        obj.removeClass('invalid-field');
        this.setState({
          assignmentUpdateAction: true,
          editAssignmentBtnStyle: 'nucleus-submit-btn',
        });
        return true;
      }
    }
  }

  validateCheckOutDate(date, obj) {
    const checkInDate = this.assignmentEditCheckIn.current.value;
    this.validateCheckInDate(checkInDate, obj);
  }

  onGetCallRequestLogDetailsAction(requestDetails) {
    console.log('onGetCallRequestLogDetailsAction', requestDetails);

    let callStatus = '';
    let callDuration = '';

    if (requestDetails.CallLogData.ID != null) {
      callStatus = requestDetails.CallLogData.Status; //requestDetails.CallLogData.WasAnswered ? "Connected" : "Missed";

      // if (requestDetails.CallLogData.InProgress){

      //     callStatus = "In Progress";
      // }
      // else
      if (requestDetails.CallLogData.WasAnswered) {
        // get total seconds between the times
        let delta = Math.abs((new Date(requestDetails.CallLogData.EndTime) as any) - (new Date(requestDetails.CallLogData.StartTime) as any)) / 1000;
        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        // what's left is seconds
        const seconds = Math.floor(delta % 60);
        const duration = this.pad2(hours) + ':' + this.pad2(minutes) + ':' + this.pad2(seconds);
        callDuration = duration;
      }
    }

    let isAutor = true;
    if (requestDetails.NotesUserID && requestDetails.NotesUserID.toUpperCase() != AuthStore.getUserID().toUpperCase()) {
      isAutor = false;
    }

    this.setState({
      reqID: requestDetails.ID,
      reqDateTime: requestDetails.RequestDateTime,
      reqEmergency: requestDetails.Emergency ? 'Y' : 'N',
      reqStatus: requestDetails.Status,
      reqHandledDateTime: requestDetails.HandledDateTime,
      reqHandler: requestDetails.HandlerName,
      reqEscalated: requestDetails.Escalated,
      reqRequests: requestDetails.Num,
      reqNotes: requestDetails.Notes,

      reqCallID: requestDetails.CallLogData.ID,
      reqCallDuration: callDuration,
      reqCallStatus: callStatus,
      reqCallParticipants: requestDetails.CallLogData.ID != null ? requestDetails.CallLogData.Participants : [],
      reqCallNotes: requestDetails.CallLogData.ID != null ? requestDetails.CallLogData.CallLogNotes : '',

      saveNoteBtnStyle: isAutor ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled',
    });

    ($('#modalRequestDetails') as unknown as JQuery).modal('open');
  }

  handleCloseRequestDetailsModal() {
    ($('#modalRequestDetails') as unknown as JQuery).modal('close');
  }

  handleSaveRequestNotes(notes: string) {
    CarePatientStore.updateCallRequestLogNotes({
      CallRequestLogID: this.state.reqID,
      Notes: notes,
      UserID: AuthStore.getUserID(),
    });

    this.setState({
      saveNoteBtnStyle: 'nucleus-submit-btn-disabled',
    });
  }

  onUpdateCallRequestLogNotesAction(response) {
    this.setState({
      saveNoteBtnStyle: 'nucleus-submit-btn',
    });

    if (response.ok) {
      ($('#modalRequestDetails') as unknown as JQuery).modal('close');
    } else {
      ($('#modalRequestDetails') as unknown as JQuery).modal('close');
    }
  }

  pad2(number) {
    return (number < 10 ? '0' : '') + number;
  }

  onOpenPatientAssignmentModalAction(assignmentData) {
    console.log('onOpenPatientAssignmentModalAction', assignmentData);

    this.setState({
      selectedAssignment: assignmentData,

      assignmentDetailsMode: 1,

      //assignmentModalOptionLabel : "Delete",
      //assignmentModeEdit : '',
      //assignmentModeDelete : 'hide',
      //assignmentModalOptionColor : 'txt-red',

      //deleteAssignmentBtnStyle : 'nucleus-submit-btn',

      assignmentUpdateAction: false,
      assignmentCheckInAction: false,
      assignmentCheckOutAction: false,
    });

    this.assignmentCaregiver.current.value = assignmentData.assignmentCaregiverName;
    this.assignmentPatient.current.value = this.props.patientFirstName + ' ' + this.props.patientLastName;

    this.assignmentEditDateTime.current.value =
      new Date(assignmentData.assignmentDate).toLocaleDateString('en-US', this.dateOptions) +
      ' ' +
      new Date(assignmentData.assignmentDate).toLocaleTimeString('en-US', this.timeOptions);

    this.textAssignmentEditMessage.current.value = assignmentData.assignmentMessage;
    this.assignmentEditCheckOut.current.value = '';
    this.assignmentEditCheckIn.current.value = '';

    console.log('Comentario: ', assignmentData.assignmentMessage);

    ($('#modalEditAssignment') as unknown as JQuery).modal('open');

    if (assignmentData.assignmentCheckOut.length > 0) {
      this.setState({
        assignmentDetailsMode: 3,
      });
      this.assignmentEditCheckOut.current.value =
        new Date(assignmentData.assignmentCheckOut).toLocaleDateString('en-US', this.dateOptions) +
        ' ' +
        new Date(assignmentData.assignmentCheckOut).toLocaleTimeString('en-US', this.timeOptions);
      this.assignmentEditCheckIn.current.value =
        new Date(assignmentData.assignmentCheckIn).toLocaleDateString('en-US', this.dateOptions) +
        ' ' +
        new Date(assignmentData.assignmentCheckIn).toLocaleTimeString('en-US', this.timeOptions);
      //@ts-ignore
      this.refs.assignmentEditCheckIn.rawValue = assignmentData.assignmentCheckIn;
    } else if (assignmentData.assignmentCheckIn.length > 0) {
      this.setState({
        assignmentDetailsMode: 2,
      });

      this.assignmentEditCheckIn.current.value =
        new Date(assignmentData.assignmentCheckIn).toLocaleDateString('en-US', this.dateOptions) +
        ' ' +
        new Date(assignmentData.assignmentCheckIn).toLocaleTimeString('en-US', this.timeOptions);
      //@ts-ignore
      this.refs.assignmentEditCheckIn.rawValue = assignmentData.assignmentCheckIn;
    } else {
      this.setState({
        assignmentDetailsMode: 1,
      });
    }
  }

  handleCloseEditAssignmentModal() {
    ($('#modalEditAssignment') as unknown as JQuery).modal('close');
  }

  handleEditAssignmentMessage() {
    this.setState({
      assignmentUpdateAction: true,
    });
  }

  handleSubmitEditAssignment() {
    this.setState({
      editAssignmentBtnStyle: 'nucleus-submit-btn-disabled',
    });

    let assignmentDateParam = '';
    if (this.assignmentEditDateTime.current.value) {
      const assignmentDate = new Date(this.assignmentEditDateTime.current.value);
      assignmentDateParam = assignmentDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + assignmentDate.toLocaleTimeString('en-US', this.timeOptions);

      console.log('Formatted AssignmentDateParam', assignmentDateParam);
    }

    let checkInDateParam = '';
    if (this.assignmentEditCheckIn.current.value) {
      console.log('Formatting CheckInDate', this.assignmentEditCheckIn.current.value);

      const checkInDate = new Date(this.assignmentEditCheckIn.current.value);
      checkInDate.setHours(checkInDate.getHours() + this.controlTimezone * -1);
      checkInDateParam = checkInDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + checkInDate.toLocaleTimeString('en-US', this.timeOptions);

      console.log('Formatted CheckInDate', checkInDateParam);
    }

    let checkOutDateParam = '';
    if (this.assignmentEditCheckOut.current.value) {
      console.log('Formatting CheckOutDate', this.assignmentEditCheckOut.current.value);
      const checkOutDate = new Date(this.assignmentEditCheckOut.current.value);
      checkOutDate.setHours(checkOutDate.getHours() + this.controlTimezone * -1);

      checkOutDateParam = checkOutDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + checkOutDate.toLocaleTimeString('en-US', this.timeOptions);

      console.log('Formatted CheckOutDate', checkOutDateParam);
    }
    this.textAssignmentEditMessage.current.focus();

    CarePatientStore.updateAssignment({
      ID: this.state.selectedAssignment.assignmentID,
      CaregiverID: this.state.selectedAssignment.assignmentCaregiverID,
      Message: this.textAssignmentEditMessage.current.value,
      AssignmentDate: assignmentDateParam,
      CheckInDate: checkInDateParam,
      CheckOutDate: checkOutDateParam,
    });
  }

  onAssignmentUpdatedAction(response) {
    console.log('onAssignmentUpdatedAction');
    console.log('Response', response);

    this.setState({
      editAssignmentBtnStyle: 'nucleus-submit-btn',
    });

    if (response.ok) {
      ($('#modalEditAssignment') as unknown as JQuery).modal('close');

      CarePatientStore.getPatientAssignments({
        PatientID: this.state.patientID,
        ShowAll: true,
      });

      setTimeout(function () {
        Message.show(AuthStore.getPatientLabel() + ' visit updated.');
      }, 500);
    } else {
      Message.show('Error updating ' + AuthStore.getPatientLabel().toLowerCase() + ' visit.');
    }
  }

  handleRefresh() {
    //window.location.reload();
    CarePatientStore.getPatientData({
      PatientID: this.props.patientId,
    });
  }

  handleRestartDevice() {
    console.log('handleRestartDevice ', this.state.selectedDeviceMacAddress);
    console.log('handleRestartDevice selectedDeviceHexnodeID', this.state.selectedDeviceHexnodeID);
    // The legacy method called this ... but is now unnecessary
    // CarePatientStore.handlePatientProfileInfoRefresh({
    //     DeviceID : this.state.selectedDeviceToRemove,
    //     DeviceMac : this.state.selectedDeviceMacAddress
    // });
    this.handleCancelUnassignDevice();
    this.requestDeviceReboot(this.state.selectedDeviceMacAddress);
  }

  handleVolumeMouseUp(e) {
    console.log('Up', e.target.value);
    CarePatientStore.updateDeviceVolume({
      DeviceID: this.state.selectedDeviceToRemove,
      RingtoneVolume: e.target.value,
    });
  }

  handleDeviceDefaultCallShortcut = e => {
    console.log('handleDeviceDefaultCallShortcut', e.target.checked);
    CarePatientStore.updateDeviceCallShortcut({
      DeviceID: this.state.selectedDeviceToRemove,
      PatientID: this.props.patientId,
      Value: e.target.checked,
    });
  };

  onSelectedDeviceCallShortcutUpdatedAction = response => {
    console.log('onSelectedDeviceCallShortcutUpdatedAction');
    console.log(response);

    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Device Call Shortcut ' + responseResult);
    } else {
      Message.show('Error updating Device Call Shortcut.');
    }
  };

  onDeviceVolumeUpdatedAction(response) {
    console.log('onDeviceVolumeUpdatedAction');

    console.log(response);
    if (response.ok) {
      Message.show('Device ringtone volume updated to: ' + response.volume);

      if (response.volume == 0) {
        console.log('Volume DISABLED');
        this.setState({
          selectedDeviceVolumeBarStyle: 'deviceVolume0',
        });
      } else {
        console.log('Volume ENABLED');
        this.setState({
          selectedDeviceVolumeBarStyle: 'deviceVolume',
        });
      }
    } else {
      Message.show('Error updating Device ringtone volume');
    }
  }

  getLogDate(date) {
    const day = date.getDate();
    const monthIndex = date.getMonth() + 1;

    return '' + monthIndex + '' + day;
  }

  handleCloseEditMemberModal() {
    ($('#modalEditMember') as unknown as JQuery).modal('close');
  }

  handleMemberModalMode() {
    if (this.state.memberModalOptionLabel == 'Delete') {
      this.setState({
        memberModalOptionLabel: 'Cancel',
        memberModeEdit: 'hide',
        memberModeDelete: '',
        memberModalOptionColor: '',
      });
    } else {
      this.setState({
        memberModalOptionLabel: 'Delete',
        memberModeEdit: '',
        memberModeDelete: 'hide',
        memberModalOptionColor: 'txt-red',
      });
    }
  }

  onOpenFamilyMemberModalAction(memberData) {
    console.log('onOpenFamilyMemberModalAction', memberData);

    this.setState({
      selectedMemberID: memberData.memberID,
      selectedMemberFirstName: memberData.memberFirstName,
      selectedMemberLastName: memberData.memberLastName,
      selectedMemberEmail: memberData.memberEmail,
      selectedMemberTelephone: memberData.memberTelephone ? memberData.memberTelephone : '',
      selectedMemberReceiveUpdates: memberData.memberReceiveUpdates,
      selectedMemberProfileCreated: memberData.memberProfileCreated,
      selectedMemberAccountAutoAnswer: memberData.AccountAutoAnswer,
      selectedMemberAutoAnswer: memberData.MemberAutoAnswer,
      selectedMemberAdmin: memberData.MemberAdmin,

      memberModalOptionLabel: 'Delete',
      memberModeEdit: '',
      memberModeDelete: 'hide',
      memberModalOptionColor: 'txt-red',

      deleteMemberBtnStyle: 'nucleus-submit-btn',
      btnResendMemberEmailStyle: 'nucleus-submit-btn',
    });

    this.memberFirstName.current.value = memberData.memberFirstName;
    this.memberLastName.current.value = memberData.memberLastName;
    this.memberEmail.current.value = memberData.memberEmail;

    ($('#modalEditMember') as unknown as JQuery).modal('open');
  }

  handleSubmitEditMember() {
    let familyMemberTelephone = this.state.selectedMemberTelephone;
    if (familyMemberTelephone.length > 0) {
      //console.log("LE Telephone avant:", familyMemberTelephone, familyMemberTelephone.length);
      familyMemberTelephone = familyMemberTelephone.replace(/[()-\s]/g, '');
      const containsLetter = /[a-zA-Z]/.test(familyMemberTelephone);
      if (containsLetter) {
        this.setState({
          errorMemberEmailVisibility: 'hide',
          errorMemberTelephoneVisibility: '',
          errorMemberTelephoneText: 'Invalid Telephone.',
        });
        return;
      }
      if (familyMemberTelephone.length < 12) {
        this.setState({
          errorMemberEmailVisibility: 'hide',
          errorMemberTelephoneVisibility: '',
          errorMemberTelephoneText: 'Invalid Telephone. It must be at least 10 digits.',
        });
        return;
      }
    }

    const updateFamilyMemberData = {
      FamilyMemberID: this.state.selectedMemberID,
      FirstName: this.memberFirstName.current.value,
      LastName: this.memberLastName.current.value,
      Email: null,
      Telephone: familyMemberTelephone,
      Password: null,
      //ReceiveUpdates: this.refs.checkMemberReceiveUpdates.checked
    };
    console.log('updateFamilyMember ==>', updateFamilyMemberData);
    this.setState({
      editMemberBtnStyle: 'nucleus-submit-btn-disabled',
      errorMemberEmailVisibility: 'hide',
      errorTelephoneVisibility: 'hide',
    });
    CarePatientStore.updateFamilyMember(updateFamilyMemberData);
  }

  onUpdateFamilyMemberAction(response) {
    if (response.ok) {
      // this.setState({
      //     familyMembersArray : response.FamilyMembers
      // });
      CarePatientStore.getFamilyMembers({
        PatientID: this.state.patientID,
      });

      ($('#modalEditMember') as unknown as JQuery).modal('close');
      Message.show(AuthStore.getFamilyLabel() + ' updated');
    } else if (response.emailRegistered) {
      this.setState({
        errorMemberEmailVisibility: '',
        errorMemberTelephoneVisibility: 'hide',
      });
    } else if (response.telephoneRegistered) {
      this.setState({
        errorMemberEmailVisibility: 'hide',
        errorMemberTelephoneVisibility: '',
        errorMemberTelephoneText: 'Telephone already registered. ',
      });
    } else {
      this.setState({
        errorMemberEmailVisibility: 'hide',
        errorMemberTelephoneVisibility: '',
        errorMemberTelephoneText: response.error,
      });
    }

    this.setState({
      editMemberBtnStyle: ' nucleus-submit-btn',
    });
  }

  onUpdateFamilyMemberNameAction(response) {
    console.log('onUpdateFamilyMemberNameAction', response);
    if (response.ok) {
      // CarePatientStore.getFamilyMembers({
      //     PatientID : this.state.patientID
      // });
      ($('#modalEditMember') as unknown as JQuery).modal('close');
      Message.show('Family Member updated');
    } else {
      Message.show('Error updating Family Member');
    }
    this.setState({
      editMemberBtnStyle: ' nucleus-submit-btn',
    });
  }
  onUpdateFamilyMemberTelephoneAction(response) {
    console.log('onUpdateFamilyMemberTelephoneAction', response);
    if (!response.ok) {
      Message.show('Error updating Family Member Telephone');
    }
  }

  validateTxtMemberInput() {
    if (refs.memberEmail.current.value.length > 0) {
      this.setState({
        errorMemberEmailVisibility: 'hide',
      });
    }
    if (this.state.familyMemberTelephone?.length > 0) {
      this.setState({
        errorMemberTelephoneVisibility: 'hide',
      });
    }
  }

  onTelephoneInputChange = number => {
    console.log('onTelephoneInputChange');
    console.log('onTelephoneInputChange number', number);
    this.state.selectedMemberTelephone = number;
    if (number.length > 0) {
      this.setState({
        errorMemberTelephoneVisibility: 'hide',
      });
    }
    console.log('onTelephoneInputChange this.state.selectedMemberTelephone', this.state.selectedMemberTelephone);
  };

  handleEnableMemberAutoAnswer(event) {
    console.log('handleEnableMemberAutoAnswer', event.target.checked);

    CarePatientStore.updateFamilyMemberEnableAutoAnswer({
      PatientID: this.state.patientID,
      FamilyID: this.state.selectedMemberID,
      EnableAutoAnswer: event.target.checked,
    });
  }

  onEnableFamilyMemberAutoAnswerUpdatedAction(response) {
    //console.log("onEnableFamilyMemberAutoAnswerUpdatedAction", response);
    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Auto Answer ' + responseResult);
    } else {
      Message.show('Error updating Auto Answer.');
    }
  }

  handleEnableMemberAdmin(event) {
    console.log('handleEnableMemberAutoAnswer', event.target.checked);

    CarePatientStore.updateFamilyMemberAdmin({
      PatientID: this.state.patientID,
      FamilyID: this.state.selectedMemberID,
      Admin: event.target.checked,
    });
  }

  onFamilyMemberAdminUpdatedAction(response) {
    //console.log("onFamilyMemberAdminUpdatedAction", response);

    if (response.ok) {
      const responseResult = response.admin ? ' Enabled.' : ' Disabled.';
      Message.show('Admin premission is ' + responseResult);

      CarePatientStore.getFamilyMembers({
        PatientID: this.state.patientID,
      });
    } else {
      Message.show('Error updating Admin premission.');
    }
  }

  handleSubmitDeleteMember() {
    this.setState({
      deleteMemberBtnStyle: 'nucleus-submit-btn-disabled',
    });

    CarePatientStore.deleteFamilyMember({
      FamilyMemberID: this.state.selectedMemberID,
      PatientID: this.state.patientID,
    });
  }

  onDeleteFamilyMemberAction(response) {
    if (response.ok) {
      // this.setState({
      //     familyMembersArray : response.FamilyMembers
      // });
      CarePatientStore.getFamilyMembers({
        PatientID: this.state.patientID,
      });
      ($('#modalEditMember') as unknown as JQuery).modal('close');
      Message.show('Family Member deleted');
    } else {
      Message.show('Error deleting Family Member');
    }
    this.setState({
      deleteMemberBtnStyle: 'nucleus-submit-btn',
    });
  }

  handleResendMemberEmail() {
    this.setState({
      btnResendMemberEmailStyle: 'nucleus-submit-btn-disabled',
    });
    CarePatientStore.reSendInvite({
      FamilyMemberID: this.state.selectedMemberID,
    });
    //Listen to "onResendInvite"
  }
  onResendInviteAction(response) {
    this.setState({
      btnResendMemberEmailStyle: 'nucleus-submit-btn',
    });
    if (response.ok) {
      ($('#modalEditMember') as unknown as JQuery).modal('close');
      Message.show('The Invite has been resent');
    } else {
      Message.show('There was an error resending the email, please try again');
    }
  }

  onOpenEditNotesModalAction(notesData) {
    console.log('onOpenEditNotesModalAction', notesData);

    const isAutor = notesData.UserID == AuthStore.getUserID().toUpperCase();
    //console.log("Is Autor", isAutor);

    this.setState({
      noteModalOptionLabel: 'Delete',
      noteModeEdit: '',
      noteModeDelete: 'hide',
      noteModalOptionColor: 'txt-red',
      noteModalOptionStyle: isAutor ? '' : ' hide',

      txtEditNoteState: isAutor ? '' : 'disabled',
      saveEditNoteBtnStyle: isAutor ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled',
      deleteNoteBtnStyle: isAutor ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled',
      selectedNoteId: notesData.NoteID,
      selectedNoteContent: notesData.Note,
      selectedNoteUser: notesData.User,
      selectedNoteType: notesData.Type,
      selectedNoteDateTime: notesData.DateTime,
    });
    ($('#modalPatientEditNote') as unknown as JQuery).modal('open');
  }

  handleEditNoteModalMode() {
    if (this.state.noteModalOptionLabel == 'Delete') {
      this.setState({
        noteModalOptionLabel: 'Cancel',
        noteModeEdit: 'hide',
        noteModeDelete: '',
        noteModalOptionColor: '',
      });
    } else {
      this.setState({
        noteModalOptionLabel: 'Delete',
        noteModeEdit: '',
        noteModeDelete: 'hide',
        noteModalOptionColor: 'txt-red',
      });
    }
  }

  handleCloseEditNoteModal() {
    ($('#modalPatientEditNote') as unknown as JQuery).modal('close');
  }

  validateEditNoteText() {
    if (this.textEditPatientNote.current.value.length > 0) {
      this.setState({
        txtEditNoteState: '',
        saveEditNoteBtnStyle: 'nucleus-submit-btn',
      });
    } else {
      this.setState({
        txtEditNoteState: '',
        saveEditNoteBtnStyle: 'nucleus-submit-btn-disabled',
      });
    }
  }

  handleSaveEditNote() {
    if (this.state.selectedNoteType == 'Request Note') {
      Message.show('Invalid Operation Update Request Note');
      return;
    }

    if (this.state.selectedNoteType == 'Call Note') {
      Message.show('Invalid Operation Update Call Note');
      return;
    }

    CarePatientStore.updatePatientNote({
      ID: this.state.selectedNoteId,
      Note: this.textEditPatientNote.current.value,
      UserID: AuthStore.getUserID(),
    });
    this.setState({
      txtEditNoteState: 'disabled',
      saveEditNoteBtnStyle: 'nucleus-submit-btn-disabled',
    });
  }

  onUpdatePatientNoteAction(response) {
    if (response.ok) {
      ($('#modalPatientEditNote') as unknown as JQuery).modal('close');
      Message.show(AuthStore.getPatientLabel() + ' Note updated');
    } else {
      Message.show('Error updating ' + AuthStore.getPatientLabel() + ' Note, please try again.');
    }
    this.setState({
      txtEditNoteState: '',
      saveEditNoteBtnStyle: 'nucleus-submit-btn',
    });
  }

  handleSubmitDeleteNote() {
    CarePatientStore.deletePatientNote({
      ID: this.state.selectedNoteId,
    });
    this.setState({
      deleteNoteBtnStyle: 'nucleus-submit-btn-disabled',
    });
  }

  onDeletePatientNoteAction(response) {
    if (response.ok) {
      ($('#modalPatientEditNote') as unknown as JQuery).modal('close');
      Message.show(AuthStore.getPatientLabel() + ' Note deleted');
    } else {
      Message.show('Error deleting ' + AuthStore.getPatientLabel() + ' Note, please try again.');
    }
    this.setState({
      deleteNoteBtnStyle: 'nucleus-submit-btn',
    });
  }

  onUpdateCallLogNotesAction(response) {
    if (response.ok) {
      Message.show('Call Log Note updated');
    } else {
      Message.show('There was a problem updating the call log note');
    }
  }

  // MESSAGES Section
  onOpenEditMessageModalAction(messageData) {
    console.log('onOpenEditMessageModalAction');
    console.log(messageData);

    let messageActive = false;
    const oneDay = 1000 * 60 * 60 * 24;
    const daysBeforeDelivery = (new Date(messageData.deliveryDateTime).getTime() - new Date().getTime()) / oneDay;
    const minsBeforeDelivery = daysBeforeDelivery * 1440;

    if (daysBeforeDelivery > 0 && minsBeforeDelivery > 5) {
      messageActive = true;
    }

    const messageCanBeUpdated = messageActive && messageData.editable;

    this.setState({
      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',

      saveEditMessageBtnStyle: messageCanBeUpdated ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled',
      deleteMessageBtnStyle: 'nucleus-submit-btn',
      selectedPatientMessageId: messageData.patientMessageID,
      selectedMessageId: messageData.messageID,
      selectedMessageContent: messageData.message,
      selectedMessageEditable: messageCanBeUpdated,
      selectedMessageDeletable: messageActive,
    });
    ($('#modalPatientEditMessage') as unknown as JQuery).modal('open');
  }

  handleCloseEditMessageModal() {
    ($('#modalPatientEditMessage') as unknown as JQuery).modal('close');
  }

  handleEditMessageModalMode() {
    if (this.state.messageModalOptionLabel == 'Delete') {
      this.setState({
        messageModalOptionLabel: 'Cancel',
        messageModeEdit: 'hide',
        messageModeDelete: '',
        messageModalOptionColor: '',
      });
    } else {
      this.setState({
        messageModalOptionLabel: 'Delete',
        messageModeEdit: '',
        messageModeDelete: 'hide',
        messageModalOptionColor: 'txt-red',
      });
    }
  }
  validateEditMessageText() {
    if (this.textEditPatientMessage.current.value.length > 0) {
      this.setState({
        saveEditMessageBtnStyle: 'nucleus-submit-btn',
      });
    } else {
      this.setState({
        saveEditMessageBtnStyle: 'nucleus-submit-btn-disabled',
      });
    }
  }

  handleSaveEditMessage() {
    CarePatientStore.updatePatientMessage({
      ID: this.state.selectedMessageId,
      Message: this.textEditPatientMessage.current.value,
    });
    // Listen to "onUpdatePatientMessage"
    this.setState({
      saveEditMessageBtnStyle: 'nucleus-submit-btn-disabled',
    });
  }

  onUpdatePatientMessageAction(response) {
    if (response.ok) {
      CarePatientStore.getPatientMessages({
        PatientID: this.state.patientID,
      });

      ($('#modalPatientEditMessage') as unknown as JQuery).modal('close');
      Message.show(AuthStore.getPatientLabel() + ' Message updated');
    } else {
      Message.show('Error updating ' + AuthStore.getPatientLabel() + ' Message, please try again.');
    }
    this.setState({
      saveEditMessageBtnStyle: 'nucleus-submit-btn',
    });
  }

  handleSubmitDeleteMessage() {
    CarePatientStore.deletePatientMessage({
      MessageID: this.state.selectedPatientMessageId,
    });
    this.setState({
      deleteMessageBtnStyle: 'nucleus-submit-btn-disabled',
    });
    // Listen to "onDeletePatientMessage"
  }
  onDeletePatientMessageAction(response) {
    if (response.ok) {
      CarePatientStore.getPatientMessages({
        PatientID: this.state.patientID,
      });

      ($('#modalPatientEditMessage') as unknown as JQuery).modal('close');
      Message.show(AuthStore.getPatientLabel() + ' Message deleted');
    } else {
      Message.show('Error deleting ' + AuthStore.getPatientLabel() + ' Message, please try again.');
    }
    this.setState({
      deleteMessageBtnStyle: 'nucleus-submit-btn',
    });
  }

  // MESSAGE TO PATIENT
  // Changed on Dec-2017 - CARE 1895
  handleNewMessageToPatient() {
    ($('#modalMessageToPatient') as unknown as JQuery).modal('open');
    this.textMessageToPatient.current.value = '';
    const currentTime = new Date();
    this.setState({
      saveNewMessageStyle: 'nucleus-submit-btn-disabled',
      messageDateTime: currentTime,
    });
    // this.refs.messageDateTime.value =
    // 	currentTime.toLocaleDateString("en-US", this.dateOptions) + " " +
    // 	currentTime.toLocaleTimeString("en-US", this.timeOptions);
  }
  handleCloseNewMessageModal() {
    ($('#modalMessageToPatient') as unknown as JQuery).modal('close');
  }
  validateNewMessageText() {
    if (refs.textMessageToPatient.current.value.length > 0 && this.state.messageDateTime && this.state.messageDateTime.toString().length > 0) {
      this.setState({
        saveNewMessageStyle: 'nucleus-submit-btn',
      });
    } else {
      this.setState({
        saveNewMessageStyle: 'nucleus-submit-btn-disabled',
      });
    }
  }
  handleSaveNewMessage() {
    //alert(this.state.patientID);
    Message.show('Error, web service deprecated');
    console.log('Error, web service deprecated');
  }
  onMessageToPatientSentAction(response) {
    if (response.ok) {
      CarePatientStore.getPatientMessages({
        PatientID: this.state.patientID,
      });

      ($('#modalMessageToPatient') as unknown as JQuery).modal('close');
      Message.show('Message sent to ' + this.props.patientFullName);
    } else {
      Message.show('Error sending message to ' + AuthStore.getPatientLabel() + ' device, please try again.');
    }
    this.setState({
      saveNewMessageStyle: 'nucleus-submit-btn',
    });
  }

  refreshDevices = () => {
    CarePatientStore.getPatientDevices({
      PatientID: this.state.patientID,
    });
    this.setState({
      loadingDevices: true,
    });
  };

  onGetPatientDevicesAction(response) {
    console.log('patientProfile:onGetPatientDevicesAction', response);
    if (response.ok) {
      let patientDevices = [].concat(response.Devices);
      patientDevices = patientDevices.sort((a, b) => {
        const textA = a.Name.toLowerCase();
        const textB = b.Name.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.setState({
        patientDevices: patientDevices,
        patientTimezoneOffset: response.TimezoneOffset,
        loadingDevices: false,
      });
    }
  }

  onPatientGetDataAction(response) {
    console.log('patientProfile:onPatientGetDataAction', response);

    if (response.ok) {
      this.setState({
        deviceCallShortcutValue: response.DeviceCallShortcut,
        patientTimezone: response.TimezoneString,

        enableBiometrics: response.EnableBiometrics,
        enableAccountBiometrics: response.AccountEnableBiometrics,
        enableBiometricsFeature: response.EnableBiometrics && response.AccountEnableBiometrics,

        enableBluetoothButtons: response.EnableBluetoothButtons,
        enableAccountBluetoothButtons: response.AccountEnableBluetoothButtons,
        enableBluetoothButtonsFeature: response.EnableBluetoothButtons && response.AccountEnableBluetoothButtons,

        enableAutoAnswer: response.EnableAutoAnswer,
        enableAccountAutoAnswer: response.AccountEnableAutoAnswer,

        enableImpairedRingtone: response.EnableImpairedRingtone,

        enableAppIncomingCall: response.EnableAppIncomingCall,
        enableAccountAppIncomingCall: response.AccountEnableAppIncomingCall,

        enableButtonsSchedule: response.EnableCallButtonSchedule || response.EnableEmergencyButtonSchedule,

        showAccountCallButton: response.AccountShowCallButton,
        showCallButton: response.ShowCallButton,

        showAccountCallButton2: response.AccountShowCallButton2,
        showCallButton2: response.ShowCallButton2,

        showAccountCheckInButton: response.AccountShowCheckInButton,
        showCheckInButton: response.ShowCheckInButton,

        showAccountEmergencyButton: response.AccountShowEmergencyButton,
        showEmergencyButton: response.ShowEmergencyButton,

        showWeather: response.ShowWeather,
        showCallPhoneFromDevice: response.CallPhoneFromDevice,

        enableAccountMemoryBox: response.AccountEnableMemoryBox,
        enableMemoryBox: response.EnableMemoryBox,
        enableBlankMemoryBox: response.EnableBlankMemoryBox,
        showCallButtonsBar: response.ShowCallButtonsBar,
        showAccountCallButtonsBar: response.AccountShowCallButtonsBar,

        enableVoiceMessage: response.EnableVoiceMessage,
        enableExternalApps: response.EnableExternalApps,
        accountExternalApps: response.AccountExternalAppsEnabled,
      });
    } else {
      console.log('Error getting ' + AuthStore.getPatientLabel().toLowerCase() + ' Information');
    }
  }

  testFunction() {
    alert('testCall');
  }

  onHandleRefreshAction(deviceInfo) {
    console.log('onHandleRefreshAction', deviceInfo);
    //this.handleRefresh();
    this.requestDeviceReboot(deviceInfo.DeviceMac);
  }

  onGetQueueSizeAction() {
    //console.log("YALLEGOLAMERAREQUEST ", requestData);
  }

  autoVideoCallAllDevices() {
    if (this.state.patientDevices?.length) {
      const devicesIds = this.state.patientDevices.map(device => device.DeviceID.toLowerCase());
      const patientName = this.props.patientFirstName + ' ' + this.props.patientLastName;
      const participants: FullCallEntity[] = [
        {
          devicesIds: devicesIds,
          name: patientName,
          entityId: this.props.patientId.toLowerCase(),
          host: false,
          type: CallType.Video,
          status: 'connected',
        },
      ];

      const callData: WebRTCCall = {
        requestId: '',
        patientId: this.props.patientId.toLowerCase(),
        callingMethod: CallingMethods.Call,
        callerName: AuthStore.getUser()?.FullName,
        participants,
        multiCall: false,
        entityId: this.props.patientId.toLowerCase(),
        patientFirstName: this.props.patientFirstName,
        patientLastName: this.props.patientLastName,
        calleeFirstName: this.props.patientFirstName,
        calleeLastName: this.props.patientLastName,
        patientName,
        patientThumb: this.props.patientImageThumb,
        type: CallType.Video,
      };
      this.props.dispatch(startCall(callData));
    }
  }

  autoStartCall(callMode) {
    console.log('autoStartCall', this.props.autoCallDeviceID, callMode, this.state.patientDevices);

    if (this.state.patientDevices != null && this.state.patientDevices != []) {
      this.state.patientDevices.map(device => {
        if (device.DeviceID == this.props.autoCallDeviceID) {
          const deviceInfo: HandleCallPayload = {
            Devices: [
              {
                id: device.DeviceID,
                name: device.Name,
              },
            ],
            PatientID: this.props.patientId,
          };
          if (callMode == '1' && this.state.enableAccountAutoAnswer && this.state.enableAutoAnswer) {
            this.onHandleBargeCallAction(deviceInfo);
          } else if (callMode == '2' && this.state.enableAccountAutoAnswer && this.state.enableAutoAnswer) {
            this.onHandleSilentBargeCallAction(deviceInfo);
          } else {
            this.onHandleVideoCallAction(deviceInfo);
          }
        }
      });
    }
  }

  onHandleAudioCallAction(deviceInfo: HandleCallPayload) {
    const callData = this.prepareCallData(deviceInfo, CallType.Audio);

    this.props.dispatch(startCall(callData));
  }

  onHandleVideoCallAction(deviceInfo: HandleCallPayload) {
    const callData = this.prepareCallData(deviceInfo, CallType.Video);

    this.props.dispatch(startCall(callData));
  }

  onHandleBargeCallAction(deviceInfo: HandleCallPayload) {
    const callData = this.prepareCallData(deviceInfo, CallType.BargeIn);

    this.props.dispatch(startCall(callData));
  }

  onHandleSilentBargeCallAction(deviceInfo: HandleCallPayload) {
    const callData = this.prepareCallData(deviceInfo, CallType.SilentBargeIn);

    this.props.dispatch(startCall(callData));
  }

  prepareCallData = (deviceInfo: HandleCallPayload, callType: CallType) => {
    const deviceId = deviceInfo.Devices[0].id;
    const pendingRequestOnQueue = CareQueueStore.getPendingRequestByPatient(this.props.patientId, deviceId);
    console.log('Pending REQUEST: ', pendingRequestOnQueue);
    if (pendingRequestOnQueue.length > 0) {
      this.setState({
        patientPendingRequest: pendingRequestOnQueue,
        patientPendingRequestIcon: ' ',
      });
    }

    const patientName = this.props.patientFirstName + ' ' + this.props.patientLastName;
    const singleDevice = deviceInfo.Devices[0];
    const participants: FullCallEntity[] = [
      {
        devicesIds: [singleDevice.id],
        name: patientName,
        entityId: this.props.patientId.toLowerCase(),
        host: false,
        status: 'connected',
        type: callType,
      },
    ];

    const callData: WebRTCCall = {
      requestId: pendingRequestOnQueue.toLowerCase(),
      patientId: this.props.patientId.toLowerCase(),
      callingMethod: CallingMethods.Call,
      participants,
      callerName: AuthStore.getUser().FullName,
      multiCall: false,
      patientFirstName: this.props.patientFirstName,
      patientLastName: this.props.patientLastName,
      calleeFirstName: this.props.patientFirstName,
      calleeLastName: this.props.patientLastName,
      patientName,
      deviceName: singleDevice.name,
      patientThumb: this.props.patientImageThumb,
      type: callType,
    };

    return callData;
  };

  requestDeviceReboot(deviceMac) {
    CarePatientStore.requestRebootDevice({
      Mac: deviceMac,
      HexnodeID: this.state.selectedDeviceHexnodeID,
    });
  }

  onRequestRestartDeviceAction(deviceData) {
    console.log('onRequestRestartDeviceAction', deviceData);

    CarePatientStore.requestRebootDevice({
      Mac: deviceData.DeviceMac,
      DeviceID: deviceData.DeviceID,
    });
  }

  //DEVICE OPTIONS
  onOpenEditPatientDeviceModalAction(deviceInfo) {
    console.log('onOpenEditPatientDeviceModalAction', deviceInfo);

    let t = 'Device';
    if (deviceInfo.IsLegacyDevice) {
      t = 'Device Mac';
    } else if (deviceInfo.HexnodeID) {
      t = 'Hexnode ID';
    }

    this.setState({
      selectedDeviceToRemove: deviceInfo.DeviceID,
      selectedDeviceIdentifierTitle: t,
      selectedDeviceMacAddress: deviceInfo.DeviceMac,
      selectedDeviceHexnodeID: deviceInfo.HexnodeID,
      selectedDeviceName: deviceInfo.DeviceName,
      selectedDeviceLabel: deviceInfo.DeviceLabel,
      selectedDevicePhotoFrameMode: deviceInfo.PhotoFrameMode,
      selectedDevicePhotoFrameModeNewValue: deviceInfo.PhotoFrameMode,
      selectedDeviceVolume: deviceInfo.DeviceVolume,

      selectedDeviceConcierge: deviceInfo.DeviceConcierge,

      deviceModalOptionLabel: 'Remove',
      deviceModeEdit: '',
      deviceModeDelete: 'hide',
      deviceModalOptionColor: 'txt-red',

      deviceModalIOS: false,

      deleteDeviceBtnStyle: 'nucleus-submit-btn',
      editDeviceBtnStyle: 'nucleus-submit-btn',

      timeStamp: Date.now(),
    });

    if (deviceInfo.DeviceVolume >= 0) {
      if (deviceInfo.DeviceVolume == 0) {
        this.setState({
          selectedDeviceVolumeBarStyle: 'deviceVolume0',
        });
      } else {
        this.setState({
          selectedDeviceVolumeBarStyle: 'deviceVolume',
        });
      }
    }

    ($('#modalDeviceSelectedDetails') as unknown as JQuery).modal('open');
  }

  handleCancelUnassignDevice() {
    ($('#modalDeviceSelectedDetails') as unknown as JQuery).modal('close');
  }

  handleDeviceModalMode() {
    if (this.state.deviceModalOptionLabel == 'Remove') {
      this.setState({
        deviceModalOptionLabel: 'Cancel',
        deviceModeEdit: 'hide',
        deviceModeDelete: '',
        deviceModalOptionColor: '',
      });
    } else {
      this.setState({
        deviceModalOptionLabel: 'Remove',
        deviceModeEdit: '',
        deviceModeDelete: 'hide',
        deviceModalOptionColor: 'txt-red',
      });
    }
  }

  handleSelectedDeviceTogglePhotoFrameModeChanged = newValue => {
    this.setState({
      selectedDevicePhotoFrameModeNewValue: newValue,
    });
    CarePatientStore.updatePatientDevicePhotoFrameMode(this.state.selectedDeviceToRemove, newValue, this.handleUpdateDevicePhotoFrameModeResponseCallback);
  };

  handleUpdateDevicePhotoFrameModeResponseCallback = response => {
    if (response?.ok) {
      Message.show('PhotoFrame Mode for device ' + this.state.selectedDeviceName + (this.state.selectedDevicePhotoFrameModeNewValue ? ' enabled ' : ' disabled'));
      CarePatientStore.getPatientDevices({
        PatientID: this.props.patientId,
      });
      this.setState(prevState => ({
        selectedDevicePhotoFrameMode: prevState.selectedDevicePhotoFrameModeNewValue,
      }));
    } else {
      Message.show('Error udpating device PhotoFrame Mode');
    }
  };

  handleUnassignDevice() {
    CarePatientStore.unassignDeviceToPatient({
      PatientID: this.state.patientID,
      DeviceID: this.state.selectedDeviceToRemove,
    });
    ($('#modalDeviceSelectedDetails') as unknown as JQuery).modal('close');
  }

  handleCheckEditDeviceName() {
    if (this.txtSelectedDeviceName.current.value.length > 0) {
      this.setState({
        editDeviceBtnStyle: 'nucleus-submit-btn',
      });
    } else {
      this.setState({
        editDeviceBtnStyle: 'nucleus-submit-btn-disabled',
      });
    }
  }

  handleCheckEditDeviceLabel = () => {};

  handleSubmitEditDevice() {
    console.log(this.state.selectedDeviceToRemove);
    console.log(this.state.selectedDeviceMacAddress);
    console.log(this.state.selectedDeviceHexnodeID);
    console.log(this.state.selectedDeviceName);

    CarePatientStore.updatePatientDevice({
      DeviceID: this.state.selectedDeviceToRemove,
      Name: this.txtSelectedDeviceName.current.value,
      DeviceLabel: this.txtSelectedDeviceLabel.current.value,
    });

    this.setState({
      editDeviceBtnStyle: 'nucleus-submit-btn-disabled',
    });
  }

  onDeviceUpdatedAction(response) {
    console.log('onDeviceUpdatedAction', response);
    this.setState({
      editDeviceBtnStyle: 'nucleus-submit-btn-disabled',
    });
    if (response.ok) {
      Message.show('Device updated');
      CarePatientStore.getPatientDevices({
        PatientID: this.props.patientId,
      });
    } else {
      Message.show('Sorry, there was an error updating the device information, please try again.');
    }
    ($('#modalDeviceSelectedDetails') as unknown as JQuery).modal('close');
  }

  onRebootDeviceRequestedAction(response) {
    console.log('onRebootDeviceRequestedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Reboot device requested, the device will restart soon.');

      setTimeout(() => {
        this.handleRefresh();
      }, 30000);
    } else {
      Message.show('There was a error requesting the device reboot, please refresh.');
    }
  }

  handleAddAnotherDeviceName(e) {
    //console.log("handleAddAnotherDeviceName", e);
    if (!(this.newDeviceMacID == '') && this.txtAnotherDeviceName.current.value.length > 0) {
      this.setState({
        btnAnotherDeviceStyle: 'nucleus-submit-btn-small',
      });
    } else {
      this.setState({
        btnAnotherDeviceStyle: 'nucleus-submit-btn-small-disabled',
      });
    }
    if (!(this.newDeviceMacID == '')) {
      this.txtAnotherDeviceName.current.focus();
    }
    this.setState({
      txtAnotherDeviceName: e.target.value,
    });
  }

  handleAddDeviceName(e) {
    //console.log("handleAddDeviceName", this.newDeviceMacID);
    if (!(this.newDeviceMacID == '') && this.txtDeviceName.current.value.length > 0) {
      this.setState({
        btnAddDeviceStyle: 'nucleus-submit-btn-small',
      });
    } else {
      this.setState({
        btnAddDeviceStyle: 'nucleus-submit-btn-small-disabled',
      });
    }

    if (!(this.newDeviceMacID == '')) {
      this.txtDeviceName.current.focus();
    }

    this.setState({
      txtAnotherDeviceName: e.target.value,
    });
  }

  //TODO: Refactor the method for new device and another device they are the same
  handleSelectNewDeviceMac(selectedOption) {
    console.log('Option selected:', selectedOption);
    let preAssignedName = '';
    this.state.devicesArray.forEach(device => {
      if (device.ID == selectedOption.value) {
        console.log('deviceSelected', device);
        preAssignedName = device.PreAssignedName;
      }
    });
    if (selectedOption && selectedOption.value) {
      this.newDeviceMacID = selectedOption.value;
      this.newDeviceMacLabel = selectedOption.label;
      this.newDevicePreassignedName = preAssignedName;
      if (this.txtDeviceName.current) this.txtDeviceName.current.value = preAssignedName;
      //this.refs.selectDeviceMacAddress.value = selectedOption.value;
    } else {
      //this.refs.selectDeviceMacAddress.value = "";
      this.newDeviceMacID = '';
      this.newDeviceMacLabel = '';
    }
    this.handleAddDeviceName({
      target: {
        value: preAssignedName,
      },
    });
  }
  //TODO: Refactor the method for new device and another device they are the same
  handleSelectAnotherDeviceMac(selectedOption) {
    console.log('Option selected:', selectedOption);
    let preAssignedName = '';
    this.state.devicesArray.forEach(device => {
      if (selectedOption && device.ID == selectedOption.value) {
        console.log('deviceSelected', device);
        preAssignedName = device.PreAssignedName;
      }
    });
    if (selectedOption && selectedOption.value) {
      this.newDeviceMacID = selectedOption.value;
      this.newDeviceMacLabel = selectedOption.label;
      this.newDevicePreassignedName = preAssignedName;
      if (this.txtAnotherDeviceName.current) this.txtAnotherDeviceName.current.value = preAssignedName;
      //this.refs.selectDeviceMacAddress.value = selectedOption.value;
    } else {
      //this.refs.selectDeviceMacAddress.value = "";
      this.newDeviceMacID = '';
      this.newDeviceMacLabel = '';
      this.newDevicePreassignedName = '';
    }
    this.handleAddAnotherDeviceName({
      target: {
        value: preAssignedName,
      },
    });
  }

  handleSelectDeviceOpens() {
    console.log('handleSelectDeviceOpens');
    //let thisMoment = new Date();
    //if (minutesPassed > 0.5) {
    CarePatientStore.getCareDevices({
      AccountID: AuthStore.getUserAccountID(),
    });
    //}
  }

  onPatientFamilyMembersDeleted = () => {
    console.log('onPatientFamilyMembersDeleted');
    CarePatientStore.getFamilyMembers({
      PatientID: this.props.patientId,
    });

    alert('Family Members Deleted');
  };

  render() {
    const currentSection = classNames({
      hide: this.props.currentMode != 'Info',
    });

    //let autoAnswerAllowed = (AuthStore.getAutoAnswerAllowed().toString() == "true");
    const autoAnswerAllowed = this.state.enableAccountAutoAnswer && this.state.enableAutoAnswer ? true : false;

    const patientDevicesList = [];
    if (this.state.patientDevices != null && this.state.patientDevices != []) {
      const userBargeInEnabled = AuthStore.nucleusUserBargeInEnabled;
      this.state.patientDevices.map((device, key) => {
        const restartAccess = AuthStore.canRestartDevices();
        patientDevicesList.push(
          <CarePatientDeviceItemRow
            index={key}
            key={key}
            ID={device.DeviceID}
            Mac={device.DeviceMac}
            HexnodeID={device.HexnodeID}
            DeviceUID={device.DeviceUID}
            Name={device.Name}
            Battery={device.Battery}
            Label={device.Label}
            defaultCallShortcut={this.state.patient}
            // Serial={device.Serial} DEPRECATED March 2022
            // IP={device.DeviceInfo.IP} DEPRECATED March 2022
            LastOnline={device.DeviceInfoLastCheckinTime}
            Online={device.DeviceInfoOnline}
            Version={device.DeviceInfoVersion}
            //ConnectDevice={true}
            //Temperature={device.DeviceInfo.Temp}
            //Humidity={device.DeviceInfo.Humidity}
            DeviceInfo={device.DeviceInfo}
            MdmID={device.MdmID}
            MdmDeviceID={device.MdmDeviceID}
            currentUserAdmin={AuthStore.canDeleteDevices()} // this.props.currentUserAdmin ||
            Volume={device.RingtoneVolume}
            Concierge={device.Concierge}
            AutoAnswerAllowed={autoAnswerAllowed}
            TimeZone={this.state.patientTimezone}
            TimezoneOffset={this.state.patientTimezoneOffset}
            restartDeviceAccess={restartAccess}
            UserBargeInEnabled={userBargeInEnabled}
            OS={device.OS}
            PhotoFrameMode={device.PhotoFrameMode}
          />,
        );
      });
    }

    //let availableDevicesList = [];
    const notAssignedDevices = [];
    let macOption = {};

    if (this.state.devicesArray != null && this.state.devicesArray.length) {
      this.state.devicesArray.map(device => {
        const label2 = device.HexnodeID != null ? ' - ' + device.HexnodeID : '';
        const label = device.PreAssignedName ? device.PreAssignedName + label2 : device.Mac;

        macOption = { value: device.ID, label };
        notAssignedDevices.push(macOption);
        //availableDevicesList.push(<option key={device.ID} value={device.ID} tag={device.Mac}>{device.Mac}</option>);
      });
    } else {
      notAssignedDevices.push({
        value: null,
        label: 'Loading devices...',
      });
    }
    const selectDeviceCaption = notAssignedDevices.length > 0 ? 'HexnodeID/ MDM ID...' : 'No available devices';

    let divDeviceControlsVisibility = ' ';
    let divDeviceAsignVisibility = ' hide';

    if (this.props.patientDeviceMac == 'Empty') {
      divDeviceControlsVisibility = ' hide';
      divDeviceAsignVisibility = ' hide';
    } else if (this.props.patientDeviceMac == 'No Device assigned' && !this.state.updated) {
      divDeviceControlsVisibility = ' hide ';
      divDeviceAsignVisibility = ' ';
    } else {
      divDeviceControlsVisibility = ' ';
      divDeviceAsignVisibility = ' hide';
    }

    let showDeviceControlsForAdmin = ' ';
    if (!this.props.currentUserAdmin) {
      showDeviceControlsForAdmin = ' hide';
    }

    let btnResendVisibility = ' hide';
    if (this.state.selectedMemberProfileCreated.toString() == 'false') {
      btnResendVisibility = ' ';
    }

    const autoAnswerVisibility = this.state.selectedMemberAccountAutoAnswer ? '' : '';
    const autoAnswerEnabled = this.props.currentUserAdmin && this.state.selectedMemberAccountAutoAnswer ? '' : 'disabled';
    const autoAnswerValue = this.state.selectedMemberAutoAnswer && this.state.selectedMemberAccountAutoAnswer ? 'checked' : '';

    const editMessageStyle1 = this.state.selectedMessageDeletable ? '' : ' hide';
    const editMessageStyle2 = this.state.selectedMessageEditable ? '' : ' disabled';
    const editMessageStyle3 = this.state.selectedMessageEditable ? '' : ' placeholder-gray';

    // - - - - - - - - - - - - - - - - - - - - - - -
    // Assignments Logs Section
    // - - - - - - - - - - - - - - - - - - - - - - -
    let level0Visibility = '';
    let level1Visibility = '';
    let level2Visibility = '';
    let level3Visibility = '';
    let checkoutEditable = '';
    let disabledFont = '';
    if (this.state.assignmentDetailsMode == 3) {
      level0Visibility = 'disabled';
      disabledFont = 'placeholder-gray';
      level1Visibility = '';
      level2Visibility = '';
      level3Visibility = '';
    } else if (this.state.assignmentDetailsMode == 2) {
      level0Visibility = 'disabled';
      disabledFont = 'placeholder-gray';
      level1Visibility = '';
      level2Visibility = '';
      level3Visibility = '';
      checkoutEditable = ' placeholder-black';
    } else {
      level1Visibility = '';
      level2Visibility = '';
      level3Visibility = 'disabled';
    }

    const isDefaultCallShortcutDevice = this.state.deviceCallShortcutValue != null && this.state.deviceCallShortcutValue == this.state.selectedDeviceToRemove;

    //console.log("deviceCallShortcutValue", this.state.deviceCallShortcutValue);
    //console.log("selectedDeviceToRemove", this.state.selectedDeviceToRemove);
    //console.log("isDefaultCallShortcutDevice", isDefaultCallShortcutDevice);

    return (
      <div className={'nucleus-user-pending altoImportant ' + currentSection}>
        <div className="row valign-wrapper profile-header-body">
          <br />
          <div className="col s1">
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 10 }}>
              <div style={{ position: 'absolute', marginTop: -55, marginLeft: 2 }}>
                <img
                  className=""
                  style={{ width: 90, height: 90, borderRadius: 45, objectFit: 'cover' }}
                  src={this.props.patientImageThumb ? this.props.patientImageThumb : '/img/user_placeholder.png'}
                  ref={img => (this.imgRef = img)}
                  //onError={() => (this.imgRef.src = '/img/user_placeholder.png')}
                />
              </div>
            </div>
          </div>
          <div className="col s3">
            <span className=" nucleus-labels-bold" style={{ paddingLeft: 6 }}>
              Address
            </span>
          </div>
          <div className="col s2">
            <span className=" nucleus-labels-bold">Phone Number</span>
          </div>
          <div className="col s2">{/* <span className=" nucleus-labels-bold">Birthday</span> */}</div>
          <div className="col s1">{/* <span className=" nucleus-labels-bold">Age</span> */}</div>
          <div className="col s3">{/* <span className=" nucleus-labels-bold">Emergency Contact</span> */}</div>
        </div>
        <div className="row profile-header-bottom">
          <div className="col s1 ">
            <span className=" nucleus-labels"></span>
          </div>
          <div className="col s3 " style={{ paddingLeft: 17 }}>
            <span className=" nucleus-labels">{this.props.patientAddress}</span>
          </div>
          <div className="col s2">
            <span className=" nucleus-labels"> {this.props.patientTelephone}</span>
          </div>
          <div className="col s2">
            <span className=" nucleus-labels">{/* {" "}{this.props.patientDateOfBirth} */}</span>
          </div>
          <div className="col s1">{/* <span className=" nucleus-labels"> {this.props.patientAge}</span> */}</div>
          <div className="col s3">
            {/* 
						<span className=" nucleus-labels">
							{" "}{this.props.patientContactName}
						</span>
						<br />
						<span className=" nucleus-labels">
							{" "}{this.props.patientContactTelephone}{" "}
						</span>
						*/}
            <br />
          </div>
          &nbsp;
          <br />
        </div>

        <h3 className="new-nucleus-page-subtitle-selected" onClick={this.refreshDevices}>
          {' '}
          Device(s) ({this.state.patientDevices.length})
        </h3>
        {this.state.loadingDevices && (
          <span className={'row'} style={{ marginLeft: 15, marginTop: 2, position: 'fixed' }}>
            <Spinner size={12} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
          </span>
        )}

        <div className={'devices-container ' + divDeviceControlsVisibility}>
          <div className="row no-margin">
            <div className="assignments-table-pending table-scroll-space">
              {/* <div className="assignments-table-pending nucleus-mini-table-container" > */}
              <table className="bordered highlight nucleus-table-small">
                <thead>
                  <tr>
                    <th className="table-col-4 left-align ">
                      <span className="nucleus-table-header-small"></span>
                    </th>
                    <th className="table-col-18 left-align ">
                      <span className="nucleus-table-header-small">Device Name</span>
                    </th>
                    <th className="table-col-25 left-align ">
                      <span className="nucleus-table-header-small">Connected On</span>
                    </th>
                    {/* <th className="table-col-11 center-align">
											<span className="nucleus-table-header-small">
												Room Temp / Humidity
											</span>
										</th> */}
                    <th className="table-col-6 center-align">
                      <span className="nucleus-table-header-small">Batt.</span>
                    </th>
                    <th className="table-col-8 center-align">
                      <span className="nucleus-table-header-small">Version</span>
                    </th>
                    <th className="table-col-32 center-align">
                      <span className="nucleus-table-header-small">Device Controls&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </th>
                    <th className="table-col-7 left-align">
                      <span className="nucleus-table-header-small"></span>
                    </th>
                  </tr>
                </thead>
                <tbody>{patientDevicesList}</tbody>
              </table>
            </div>
          </div>
          <br />
          <div className={'row' + this.state.deviceModeChangeVisibility + showDeviceControlsForAdmin}>
            <div className="col s12 no-margin">
              {'Assign another device to this ' + AuthStore.getPatientLabel()}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a id="device-assign-more" className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handlePanelAssignDevice}>
                {this.state.deviceModeButtonTitle}
              </a>
            </div>
          </div>
          <div className={'no-margin ' + this.state.deviceModeAssignVisibility + showDeviceControlsForAdmin}>
            <div className="row no-margin">
              <div className="col s12 no-margin">
                {'Select the MDM ID of the ' + AuthStore.getPatientLabel().toLowerCase() + ' new device and give a name to the device.'}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handlePanelAssignDevice}>
                  {this.state.deviceModeButtonTitle}
                </a>
              </div>
            </div>
            <div className="row no-margin">
              <div className="col s4 m4 l3 no-margin">
                {this.state.deviceModeButtonTitle == 'Cancel' && (
                  <Select
                    id={'select-more-device'}
                    defaultValue=""
                    //ref={c => (this.selectAnotherDeviceName = c)}
                    className="browser-default select-device-mac selectStyle"
                    placeholder={selectDeviceCaption}
                    isSearchable={true}
                    isClearable={true}
                    onChange={this.handleSelectAnotherDeviceMac}
                    onMenuOpen={this.handleSelectDeviceOpens}
                    closeMenuOnSelect={true}
                    options={notAssignedDevices}
                  />
                )}
              </div>
              <div className="col s8 m8 l9 ">
                <input
                  id={'device-assign-more-name'}
                  ref={this.txtAnotherDeviceName}
                  type="text"
                  value={this.state.txtAnotherDeviceName}
                  className="validate nucleus-input-form txt-device-mac"
                  placeholder="Click to name this device"
                  onChange={this.handleAddAnotherDeviceName}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a id="assign-more-device-submit" className={'txt-white nucleus-font-small ' + this.state.btnAnotherDeviceStyle} onClick={this.handleAssignAnotherDevice}>
                  Submit
                </a>
              </div>
            </div>
            <div className="row a-bit-lower">
              <div className="col s4 m4 l3 ">
                {' '}
                <span className="nucleus-font-small">Select from the dropdown list or filter the selection by typing the MDM ID</span>
              </div>
              <div className="col s6 m6 l7 ">
                <span className="nucleus-font-small">&nbsp;&nbsp;&nbsp;&nbsp;For example: Living Room</span>
              </div>
              <div className="col s6 m6 l7 ">
                <span className="nucleus-font-small">&nbsp;&nbsp;&nbsp;&nbsp;This name will show on the device main screen</span>
              </div>
            </div>
          </div>
        </div>

        <div className={'a-bit-lower ' + divDeviceAsignVisibility + showDeviceControlsForAdmin}>
          <div className={'row' + this.state.deviceModeChangeVisibility}>
            <div className="col s12 no-margin">
              {'No Device assigned'}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a id={'assign-device'} className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handlePanelAssignDevice}>
                {this.state.deviceModeButtonTitle}
              </a>
            </div>
          </div>

          <div className={'no-margin ' + this.state.deviceModeAssignVisibility}>
            <div className="row no-margin">
              <div className="col s12 no-margin">
                {'Select the MDM ID of the ' + AuthStore.getPatientLabel().toLowerCase() + ' new device and give a name to the device.'}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handlePanelAssignDevice}>
                  {this.state.deviceModeButtonTitle}
                </a>
              </div>
            </div>
            <div className="row no-margin">
              <div className="col s4 m4 l3 no-margin">
                <Select
                  id={'select-device'}
                  defaultValue=""
                  className="browser-default select-device-mac selectStyle"
                  placeholder={selectDeviceCaption}
                  isSearchable={true}
                  isClearable={true}
                  onChange={this.handleSelectNewDeviceMac}
                  onMenuOpen={this.handleSelectDeviceOpens}
                  options={notAssignedDevices}
                />
              </div>
              <div className="col s6 m6 l7 ">
                <input
                  id="handle-add-device-name"
                  ref={this.txtDeviceName}
                  type="text"
                  value={this.state.txtAnotherDeviceName}
                  className="validate nucleus-input-form txt-device-mac"
                  placeholder="Click to name this device"
                  onChange={this.handleAddDeviceName}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a id={'submit-device'} className={'txt-white nucleus-font-small ' + this.state.btnAddDeviceStyle} onClick={this.handleAssignDevice}>
                  Submit
                </a>
              </div>
            </div>
            <div className="row a-bit-lower">
              <div className="col s4 m4 l3 no-margin">
                {' '}
                <span className="nucleus-font-small">Select from the dropdown list or filter the selection by typing the MDM ID</span>
              </div>
              <div className="col s6 m6 l7 no-margin">
                <span className="nucleus-font-small">&nbsp;&nbsp;&nbsp;&nbsp;For example: Living Room</span>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <h3 className="new-nucleus-page-subtitle"> Activity & Configuration</h3>

        <PatientSettings
          patientID={this.state.patientID}
          patientFullName={this.props.patientFullName}
          patientZipCode={this.props.patientZipCode}
          patientDevices={this.state.patientDevices}
        />

        {/*
        ==========================
         MODAL EDIT FAMILY MEMBER  
        ==========================
        */}
        <div id="modalEditMember" className="modal modalPatientProfile modalScrollable50">
          <div className="modal-content nucleus-modal-content txt-special-color nucleus-modal-wrapper-scrollable2">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseEditMemberModal} />
            <h3 className="nucleus-page-subtitle">&nbsp; Edit {AuthStore.getFamilyLabel()}</h3>
            <a className={'nucleus-font nucleus-font-small nucleus-link'} onClick={this.handleMemberModalMode} style={{ marginLeft: 24 }}>
              <span className={this.state.memberModalOptionColor}> {this.state.memberModalOptionLabel}</span>
            </a>
            <br />
            <br />
            <div className={' modallScrollable ' + this.state.memberModeEdit}>
              <div className="row">
                <div className="col s5 m4">
                  <span className="nucleus-labels">
                    <b>Email</b>
                  </span>
                </div>
                <div className="col s7 m8" key={this.state.selectedMemberEmail}>
                  <input
                    id="memberEmail"
                    ref={this.memberEmail}
                    type="text"
                    disabled="disabled"
                    className="validate nucleus-input-form"
                    defaultValue={this.state.selectedMemberEmail}
                    onChange={this.validateTxtMemberInput}
                  />
                  <span className={'nucleus-form-error center-align ' + this.state.errorMemberEmailVisibility}>Email already exists.</span>
                </div>
              </div>

              <div className="row">
                <div className="col s5 m4">
                  <span className="nucleus-labels">
                    <b>First Name</b>
                  </span>
                </div>
                <div className="col s7 m8" key={this.state.selectedMemberFirstName}>
                  <input id="memberFirstName" ref={this.memberFirstName} type="text" className="validate nucleus-input-form" defaultValue={this.state.selectedMemberFirstName} />
                </div>
              </div>
              <div className="row">
                <div className="col s5 m4">
                  <span className="nucleus-labels">
                    <b>Last Name</b>
                  </span>
                </div>
                <div className="col s7 m8" key={this.state.selectedMemberLastName}>
                  <input id="memberLastName" ref={this.memberLastName} type="text" className="validate nucleus-input-form" defaultValue={this.state.selectedMemberLastName} />
                </div>
              </div>

              <div className="row">
                <div className="col s5 m4">
                  <span className="nucleus-labels">
                    <b>Telephone</b>
                  </span>
                </div>
                <div className="col s7 m8 a-bit-left" key="selectedFamilyTelephone">
                  <TelephoneInputUnderline
                    inputId="txtFamilyMemberTelephone"
                    value={this.state.selectedMemberTelephone}
                    onChange={this.onTelephoneInputChange}
                    style={{ marginLeft: 3 }}
                  />

                  <span className={'nucleus-form-error center-align ' + this.state.errorMemberTelephoneVisibility}>{this.state.errorMemberTelephoneText}</span>
                </div>
              </div>

              <div className={'row ' + autoAnswerVisibility}>
                <div className="col s5 m4">
                  <span className="nucleus-labels">
                    <b>Enable Auto Answer</b>
                  </span>
                </div>
                <div className="col s7 m8" key={autoAnswerValue}>
                  <input
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableMemberAutoAnswer"
                    defaultChecked={autoAnswerValue}
                    disabled={autoAnswerEnabled}
                    onChange={this.handleEnableMemberAutoAnswer}
                  />
                  <label for="checkEnableMemberAutoAnswer">&nbsp;</label>
                </div>
              </div>

              {this.props.currentUserAdmin && (
                <div className={'row '}>
                  <div className="col s5 m4">
                    <span className="nucleus-labels">
                      <b>Admin</b>
                    </span>
                  </div>
                  <div className="col s7 m8" key={this.state.selectedMemberAdmin ? 'checked' : ''}>
                    <input
                      type="checkbox"
                      className="filled-in"
                      id="checkEnableMemberAdmin"
                      defaultChecked={this.state.selectedMemberAdmin ? 'checked' : ''}
                      onChange={this.handleEnableMemberAdmin}
                    />
                    <label for="checkEnableMemberAdmin">&nbsp;</label>
                  </div>
                </div>
              )}

              {/* Disabled/ Deprecated*/}
              {/* <div className="row hide">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Receive Updates</b>
                  </span>
                </div>
                <div className="col s7">
                  <div className="switch">
                    <label key={receiveUpdatesValue}>
                      <input type="checkbox" defaultChecked={receiveUpdatesValue} />
                      <span className="lever"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              <br />
              <div className={'input-field col s12 ' + btnResendVisibility}>
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.btnResendMemberEmailStyle} onClick={this.handleResendMemberEmail}>
                      Resend Invite
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
              <br />
              <div className="input-field col s12 modalCallLogContent2">
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      <label> </label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.editMemberBtnStyle} onClick={this.handleSubmitEditMember}>
                      Save
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>

            <div className={' ' + this.state.memberModeDelete}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> Are you sure you want to delete this family member ? </span>
                </div>
              </div>

              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.deleteMemberBtnStyle} onClick={this.handleSubmitDeleteMember}>
                      Delete
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalPatientEditNote" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseEditNoteModal} />
            <h3 className="nucleus-page-subtitle">Edit {AuthStore.getPatientLabel()} Note</h3>
            <a id={'handleEditNoteModalMode'} className={'nucleus-font nucleus-font-small nucleus-link' + this.state.noteModalOptionStyle} onClick={this.handleEditNoteModalMode}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={this.state.noteModalOptionColor}> {this.state.noteModalOptionLabel}</span>
            </a>
            <br />
            <br />
            <div className={' ' + this.state.noteModeEdit}>
              <div className="row bottom-separated">
                <span className=" nucleus-font-label-title">{' Date/Time: '}</span>
                <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.selectedNoteDateTime}</span>
              </div>
              <div className="row bottom-separated">
                <span className=" nucleus-font-label-title">{' Type: '}</span>
                <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.selectedNoteType}</span>
              </div>
              <div className="row bottom-separated">
                <span className=" nucleus-font-label-title">{AuthStore.getUserLabel() + ': '}</span>
                <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.selectedNoteUser}</span>
              </div>

              <div className="row bottom-separated">
                <span className=" nucleus-font-label-title"> Note:</span>
                <div className="input-field" key={this.state.selectedNoteContent}>
                  <textarea
                    ref={this.textEditPatientNote}
                    className="nucleus-textarea-big "
                    disabled={this.state.txtEditNoteState}
                    onChange={this.validateEditNoteText}
                    defaultValue={this.state.selectedNoteContent}
                  ></textarea>
                  <br />
                </div>
              </div>

              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a id={'handleSaveEditNote'} className={'txt-white nucleus-font-modal-btn ' + this.state.saveEditNoteBtnStyle} onClick={this.handleSaveEditNote}>
                    Save
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>

            <div className={' ' + this.state.noteModeDelete}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> Are you sure you want to delete this note ? </span>
                </div>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a id="delete-edit-note" className={'txt-white nucleus-font-modal-btn ' + this.state.deleteNoteBtnStyle} onClick={this.handleSubmitDeleteNote}>
                      Delete
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalPatientEditMessage" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseEditMessageModal} />
            <h3 className="nucleus-page-subtitle">&nbsp; Edit {AuthStore.getPatientLabel()} Message</h3>
            <a className={'nucleus-font nucleus-font-small nucleus-link' + editMessageStyle1} onClick={this.handleEditMessageModalMode}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className={this.state.messageModalOptionColor}> {this.state.messageModalOptionLabel}</span>
            </a>
            <br />
            <br />
            <div className={' ' + this.state.messageModeEdit}>
              <span>&nbsp;&nbsp; Message</span>
              <div className="input-field col s12 " key={this.state.selectedMessageContent}>
                <textarea
                  id="textEditPatientMessage"
                  ref={this.textEditPatientMessage}
                  className={'nucleus-textarea-big ' + editMessageStyle3}
                  //defaultValue=""
                  disabled={editMessageStyle2}
                  onChange={this.validateEditMessageText}
                  defaultValue={this.state.selectedMessageContent}
                ></textarea>
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.saveEditMessageBtnStyle} onClick={this.handleSaveEditMessage}>
                      Save
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={' ' + this.state.messageModeDelete}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> Are you sure you want to delete this message ? </span>
                </div>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a id="handleSubmitDeleteMessage" className={'txt-white nucleus-font-modal-btn ' + this.state.deleteMessageBtnStyle} onClick={this.handleSubmitDeleteMessage}>
                      Delete
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalMessageToPatient" className="modal modalPatientProfile modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseNewMessageModal} />
            <h3 className="nucleus-page-subtitle ">New Message to {AuthStore.getPatientLabel() + ': ' + this.props.patientFullName}</h3>
            <div className="row"></div>
            <span className="nucleus-font-medium">Date/Time:</span>

            <div style={{ width: 140, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  //ref={this.messageDateTimeRef}
                  variant="dialog"
                  className="browser-default"
                  value={this.state.messageDateTime}
                  onChange={date => {
                    this.setState({
                      messageDateTime: date,
                    });
                  }}
                  autoOk={true}
                  onError={console.log}
                  //minDate={new Date("2018-01-01T00:00")}
                  format={'MM/DD/YYYY'}
                />
              </MuiPickersUtilsProvider>
            </div>
            <br />
            <br />
            <div className="input-field col s12 modalCallLogContent2 no-margin">
              <textarea ref={this.textMessageToPatient} className="nucleus-textarea-big " defaultValue="" onChange={this.validateNewMessageText}></textarea>
              <br />
              <div className="row no-margin a-bit-lower">
                <div className="col s12 center-align no-margin">
                  <a className={'txt-white nucleus-font-modal-btn ' + this.state.saveNewMessageStyle} onClick={this.handleSaveNewMessage}>
                    Send
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalDeviceSelectedDetails" className="modal modalPatientProfileInfo modalDeviceSelectedDetails">
          <div className="modal-content nucleus-modal-content txt-special-color nucleus-modal-wrapper-scrollable2">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCancelUnassignDevice} />
            <h3 className="nucleus-page-subtitle">&nbsp; {AuthStore.getPatientLabel()} Device</h3>
            {AuthStore.canDeleteDevices() && (
              <a className={'nucleus-font nucleus-font-small nucleus-link'} onClick={this.handleDeviceModalMode}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={this.state.deviceModalOptionColor}> {this.state.deviceModalOptionLabel}</span>
              </a>
            )}
            <br />
            <br />
            <div className={' modallScrollable ' + this.state.deviceModeEdit}>
              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>{this.state.selectedDeviceIdentifierTitle}</b>
                  </span>
                </div>
                {this.state.selectedDeviceIdentifierTitle == 'Hexnode ID' && (
                  <div className="col s7" key={this.state.selectedDeviceHexnodeID}>
                    <input
                      id="txtSelectedDeviceMacAddress"
                      type="text"
                      className="validate nucleus-input-form"
                      disabled="disabled"
                      defaultValue={this.state.selectedDeviceHexnodeID}
                    />
                  </div>
                )}
                {this.state.selectedDeviceIdentifierTitle != 'Hexnode ID' && (
                  <div className="col s7" key={this.state.selectedDeviceMacAddress}>
                    <input
                      id="txtSelectedDeviceMacAddress"
                      type="text"
                      className="validate nucleus-input-form"
                      disabled="disabled"
                      defaultValue={this.state.selectedDeviceMacAddress}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Device Name</b>
                  </span>
                </div>
                <div className="col s7" key={this.state.selectedDeviceName}>
                  <input
                    id="txtSelectedDeviceName"
                    ref={this.txtSelectedDeviceName}
                    type="text"
                    className="validate nucleus-input-form"
                    defaultValue={this.state.selectedDeviceName}
                    onChange={this.handleCheckEditDeviceName}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Device Label</b>
                  </span>
                </div>
                <div className="col s7" key={this.state.selectedDeviceLabel}>
                  <input
                    id="txtSelectedDeviceLabel"
                    ref={this.txtSelectedDeviceLabel}
                    type="text"
                    className="validate nucleus-input-form"
                    defaultValue={this.state.selectedDeviceLabel}
                    onChange={this.handleCheckEditDeviceLabel}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Ring Volume</b>
                  </span>
                </div>
                <div className="col s7">
                  <p className="device-icons-line-small no-margin " key={this.state.selectedDeviceVolume}>
                    <img className="device-volume-icon" src="/img/device_volume_low2.png" />
                    <input
                      ref={this.deviceVolume}
                      type="range"
                      className={this.state.selectedDeviceVolumeBarStyle}
                      min="0"
                      max="100"
                      step="1"
                      defaultValue={this.state.selectedDeviceVolume}
                      onMouseUp={this.handleVolumeMouseUp}
                    />
                    <img className="" src="/img/device_volume_high2.png" />
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Default Call Device</b>
                  </span>
                </div>
                <div className="col s7" key={isDefaultCallShortcutDevice}>
                  <input
                    ref={this.checkEnableCallShorcut}
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableCallShorcut"
                    defaultChecked={isDefaultCallShortcutDevice}
                    onChange={this.handleDeviceDefaultCallShortcut}
                  />
                  <label for="checkEnableCallShorcut">&nbsp;</label>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 0 }}>
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>
                      Enable Photo Frame Mode<span style={{ color: 'red', fontSize: 18 }}>*</span>
                    </b>
                  </span>
                </div>
                <div className="col s7" style={{ display: 'inline-flex', padding: 0, marginBottom: 0, marginTop: -7 }}>
                  <BlueToggle
                    id={'devicePhotoFrameMode'}
                    isToggled={this.state.selectedDevicePhotoFrameMode}
                    onToggleChange={this.handleSelectedDeviceTogglePhotoFrameModeChanged}
                  />
                </div>
              </div>
              <div className="row" style={{ paddingLeft: 10 }}>
                <span className="nucleus-labels" style={{ color: 'red', fontSize: 13 }}>
                  *This action will suspend all calling and video features on the Nucleus Device(s). The user will only be able to use the device(s) as a photo frame.
                </span>
              </div>

              <div className="input-field col s12 modalCallLogContent2">
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.editDeviceBtnStyle} onClick={this.handleSubmitEditDevice}>
                      Save
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={' ' + this.state.deviceModeDelete}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> Are you sure you want to remove the device from the {AuthStore.getPatientLabel().toLowerCase()}'s account? </span>
                </div>
              </div>
              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.deleteDeviceBtnStyle} onClick={this.handleUnassignDevice}>
                      Remove
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalEditAssignment" className="modal modalPatientProfile modalScrollable">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseEditAssignmentModal} />
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;Edit Visit</h3>
            <br />
            <br />
            <div className={' modallScrollable '}>
              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>{AuthStore.getCaregiverLabel()}</b>
                  </span>
                </div>
                <div className="col s7">
                  <input id="assignmentCaregiver" ref={this.assignmentCaregiver} type="text" className="validate nucleus-input-form fake-txt-box" disabled="disabled" />
                </div>
              </div>
              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>{AuthStore.getPatientLabel()}</b>
                  </span>
                </div>
                <div className="col s7">
                  <input id="assignmentPatient" ref={this.assignmentPatient} type="text" className="validate nucleus-input-form fake-txt-box" disabled="disabled" />
                  {/*<span className={ "nucleus-form-error center-align " + this.state.editAssignmentErrorPatient }>Please select a patient.</span> */}
                </div>
              </div>
              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Date Time of Visit</b>
                  </span>
                </div>
                <div className="col s7">
                  <input
                    id="assignmentEditDateTime"
                    ref={this.assignmentEditDateTime}
                    type="text"
                    className="validate nucleus-date-picker"
                    disabled={level1Visibility}
                    placeholder="  Pick a Date"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Check-in</b>
                  </span>
                </div>
                <div className="col s7">
                  <input
                    id="assignmentEditCheckIn"
                    ref={this.assignmentEditCheckIn}
                    type="text"
                    disabled={level2Visibility}
                    className="validate nucleus-date-picker placeholder-black"
                    placeholder=" Check-in"
                    autoComplete="off"
                  />
                  {/*<span className={"nucleus-form-error center-align " + this.state.editAssignmentErrorDate }>Please select a date and time.</span> */}
                </div>
              </div>

              <div className="row">
                <div className="col s5">
                  <span className="nucleus-labels">
                    <b>Check-out</b>
                  </span>
                </div>
                <div className="col s7">
                  <input
                    id="assignmentEditCheckOut"
                    ref={this.assignmentEditCheckOut}
                    disabled={level3Visibility}
                    type="text"
                    className={'validate nucleus-date-picker ' + checkoutEditable}
                    placeholder="  Check-out"
                    autoComplete="off"
                  />
                  {/*<span className={"nucleus-form-error center-align " + this.state.editAssignmentErrorDate }>Please select a date and time.</span> */}
                </div>
              </div>

              <span className="nucleus-labels">
                <b>&nbsp;&nbsp;&nbsp;Message:</b>
              </span>
              <div className="input-field col s12 modalCallLogContent2">
                <textarea
                  ref={this.textAssignmentEditMessage}
                  className={'nucleus-textarea-big ' + disabledFont}
                  disabled={level0Visibility}
                  onChange={this.handleEditAssignmentMessage}
                ></textarea>
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.editAssignmentBtnStyle} onClick={this.handleSubmitEditAssignment}>
                      Save
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalRequestDetails
          onClose={this.handleCloseRequestDetailsModal}
          requestCallID={this.state.reqCallID}
          patientFullName={this.state.patientFullName}
          requestDateTime={this.state.reqDateTime}
          requestCallParticipants={this.state.reqCallParticipants}
          requestStatus={this.state.reqStatus}
          requestHandledDateTime={this.state.reqHandledDateTime}
          requestHandler={this.state.reqHandler}
          requestEscalated={this.state.reqEscalated}
          requestRequests={this.state.reqRequests}
          requestCallDuration={this.state.reqCallDuration}
          requestCallStatus={this.state.reqCallStatus}
          requestCallNotes={this.state.reqCallNotes}
          saveNoteBtnStyle={this.state.saveNoteBtnStyle}
          requestEmergency={this.state.reqEmergency}
          requestNotes={this.state.reqNotes}
          handleSaveRequestNotes={this.handleSaveRequestNotes}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

const PatientProfileInfoFC = props => {
  return useReactRouterProps(PatientProfileInfo, props);
};

export default connect(undefined, mapDispatchToProps)(PatientProfileInfoFC);
